import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@portfolio/configs'
import { createMediaProjection } from '../projections/createMediaProjection'

export const createSectionCaseStudyCardsProjection = (localeId: LocaleId) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId, 'MEDIUM')

  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('title', localeId)},
        "cards": cards[] {
          _key,
          media${MEDIA_PROJECTION},
          "caseStudyReference": caseStudyReference -> {
            name,
            "path": path.current,
            ${coalesceLocaleField('shortDescription', localeId)},
          }
        }
    }
  `
}
