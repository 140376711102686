import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@portfolio/configs'
import { SELECTED_STATIC_RENDITION } from '../projections/createMediaProjection'

export const createSectionVideoPlayerProjection = (localeId: LocaleId) => {
  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('description', localeId)},
        video{
          ...asset-> {
              "playbackId": data.playback_ids[0].id,
              "firstFrame": "https://image.mux.com/" + data.playback_ids[0].id + "/thumbnail.jpg?time=0",
               ...(${SELECTED_STATIC_RENDITION()}{width, height}),
          }
        },
  }
`
}
