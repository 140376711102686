import { groq } from 'next-sanity'
import { LocaleId, DEFAULT_LOCALE_ID } from '@portfolio/configs'

export const createSectionIntroTextsProjection = (localeId: LocaleId) => {
  return groq`{
        _key,
        _type,
        "texts": coalesce(texts[].${localeId}, texts[].${DEFAULT_LOCALE_ID})
      }
  `
}
