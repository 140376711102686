export const Check = () => {
  return (
    <svg
      width="13"
      height="9"
      viewBox="0 0 13 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 0.711589L4.57396 9L0 4.50037L0.723434 3.78881L4.57399 7.57679L12.2766 0L13 0.711589Z"
        fill="currentColor"
      />
    </svg>
  )
}
