import { DEFAULT_LOCALE_ID, LocaleId } from '@portfolio/configs'
import { groq } from 'next-sanity'
import { createLinkProjection } from './createLinkProjection'

export const createPortableTextProjection = (
  localeId: LocaleId,
  defaultLocaleId = DEFAULT_LOCALE_ID,
) => {
  const LINK_PROJECTION = createLinkProjection(localeId, defaultLocaleId)

  return groq`{
    ...,
    markDefs[]{
      ...,
      _type == "link" => {
        _key,
        _type,
        ...${LINK_PROJECTION},
      }
    }
  }`
}
