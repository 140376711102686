import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@portfolio/configs'
import { createMediaProjection } from '../projections/createMediaProjection'
import { createSanityColorProjection } from '../projections/createSanityColorProjecton'

export const createSectionDoubleMediaWithBackgroundProjection = (
  localeId: LocaleId,
) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const COLOR_PROJECTION = createSanityColorProjection()
  return groq`{
        _key,
        _type,
        color${COLOR_PROJECTION},
        mediaCards[] {
          media${MEDIA_PROJECTION},
          ${coalesceLocaleField('mediaDescription', localeId)},
          mediaBorder,
        }
      }
  `
}
