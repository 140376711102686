import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@portfolio/configs'
import { createLinkProjection } from '../projections/createLinkProjection'

export const createSectionServicesListingProjection = (localeId: LocaleId) => {
  const LINK_PROJECTION = createLinkProjection(localeId)

  return groq`{
        _key,
        _type,
        cta${LINK_PROJECTION},
        ${coalesceLocaleField('ctaLabel', localeId)},
        servicesList[] {
          ${coalesceLocaleField('title', localeId)},
          "services": services[]->{
            ${coalesceLocaleField('title', localeId)},
          }.title
        }
      }
  `
}
