import { FC, ReactElement } from 'react'
import { MuxVideoMediaPayload, MuxVideoType } from '@portfolio/models'
import { MediaProps } from '../models/MediaProps'
import { ResponsiveMediaComponent } from './ResponsiveMediaComponent'
import { Video } from './Video'
import { VideoControlButton } from './VideoControlButton'
import { useVideoOrchestrator } from '../helpers/useVideoOrchestrator'

type MuxVideoProps = Omit<MediaProps, 'mediaPayload'> & {
  mediaPayload: MuxVideoMediaPayload
  videoOverride?: (video: MuxVideoType) => ReactElement
  videoOverrideMobile?: (video: MuxVideoType) => ReactElement
}

type VideoAdapterProps = Omit<MediaProps, 'mediaPayload'> & {
  video: MuxVideoType
}

const VideoAdapter: FC<VideoAdapterProps> = ({
  layout,
  priority,
  video,
  alt,
  loop,
  muted = true,
  controlsPosition = 'bottom-right',
  autoplay = true,
  sizes,
  hardcropRatio,
}) => {
  const { firstFrame, mp4, width, height } = video

  const { containerRef, videoControlProps, controlsProps } =
    useVideoOrchestrator({
      autoplay,
    })

  return (
    <div ref={containerRef}>
      <Video
        src={mp4}
        width={width}
        height={height}
        layout={layout}
        sizes={sizes}
        firstFrame={firstFrame}
        alt={alt}
        loop={loop}
        muted={muted}
        priority={priority}
        hardcropRatio={hardcropRatio}
        {...videoControlProps}
      />
      <VideoControlButton
        {...controlsProps}
        controlsPosition={controlsPosition}
      />
    </div>
  )
}

export const MuxVideo = ({
  mediaPayload,
  videoOverride,
  videoOverrideMobile,
  ...restOfProps
}: MuxVideoProps) => {
  const { video, mobileVideo } = mediaPayload

  const MobileComponent = (() => {
    if (!mobileVideo) {
      return undefined
    }

    if (videoOverrideMobile) {
      return videoOverrideMobile(mobileVideo)
    }

    return <VideoAdapter video={mobileVideo} {...restOfProps} />
  })()

  const DesktopComponent = (() => {
    if (videoOverride) {
      return videoOverride(video)
    }

    return <VideoAdapter video={video} {...restOfProps} />
  })()

  return (
    <ResponsiveMediaComponent
      mobileComponent={MobileComponent}
      component={DesktopComponent}
    />
  )
}
