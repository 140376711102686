import { groq } from 'next-sanity'
import { DEFAULT_REGION_ID, LocaleId, RegionId } from '@portfolio/configs'
import { createPageBuilderProjection } from './createPageBuilderProjection'
import { createSEOProjection } from './createSEOProjection'

export const createDefaultPageProjection = (
  regionId: RegionId,
  localeId: LocaleId,
) => {
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)

  return groq`coalesce(page_${regionId}, page_${DEFAULT_REGION_ID})->{
    "type":_type,
    ...,
    "disallowRobots":^.disallowRobots,
    pageBuilder[]${PAGE_BUILDER_PROJECTION},
    seo${createSEOProjection(localeId)},
  }`
}
