import { groq } from 'next-sanity'

export const createSectionTermlyProjection = () => {
  return groq`{
        _key,
        _type,
        termlyId,
      }
  `
}
