export const sizes = {
  0: '0px',
  4: '4px',
  6: '6px',
  8: '8px',
  12: '12px',
  16: '16px',
  20: '20px',
  24: '24px',
  28: '28px',
  32: '32px',
  36: '36px',
  40: '40px',
  48: '48px',
  56: '56px',
  64: '64px',
  72: '72px',
  96: '96px',
  120: '120px',
  144: '144px',
  168: '168px',
  192: '192px',

  buttonHeight: '44px',
  headerHeight: '80px',

  containerMaxWidthForSidebarLayout: '1080px',

  richTextWidth: '900px',
  techStackModalWidth: '780px',
  techStackModalTextHeight: '44px',
  homeHeroTextWidth: '740px',
  serviceBlockWidth: '720px',
  textBlocksRowGap: '106px',
  sideTextBlockWidth: '1064px',
  aboutPageContentWidth: '820px',
  aboutPageLeftColumnMaxWidth: '400px',

  desktopSectionMarginL: '144px',
  mobileSectionMarginL: '96px',

  sectionMarginS: '12px',
  sectionMarginM: '32px',

  maxWidthDesktop: '1440px',
} as const
