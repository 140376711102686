export const screenSizes = {
  xs: 375,
  sm: 430,
  md: 740,
  lg: 1000,
  xl: 1300,
  xxl: 1900,
}

export type ScreenSize = keyof typeof screenSizes

export type Breakpoint = Record<
  keyof typeof screenSizes,
  `(min-width: ${number}px)`
>

export const breakpoints: Breakpoint = Object.entries(screenSizes).reduce(
  (acc, [name, size]) => {
    return {
      ...acc,
      [name]: `(min-width: ${size.toString()}px)`,
    }
  },
  {} as Breakpoint,
)
