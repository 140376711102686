import { MediaFieldType } from '@portfolio/models'
import { styled } from '@portfolio/styles'
import { Color } from '@portfolio/models'
import { FC } from 'react'
import { Video } from '../Media/components/Video'
import { VideoControlButton } from '../Media/components/VideoControlButton'
import { Media } from '../Media/Media'
import { useVideoOrchestrator } from '../Media/helpers/useVideoOrchestrator'

type MediaWithBackgroundProps = {
  media?: MediaFieldType
  color?: Color
  mediaBorder?: 'none' | 'rounded'
  priority?: boolean
  sizes?: string
  appearance?: 'singleMedia' | 'doubleMedia' | 'slider'
}

const MediaPaddingWrap = styled('div', {
  position: 'relative',
  display: 'grid',
  justifyItems: 'center',
  alignItems: 'center',
  variants: {
    appearance: {
      singleMedia: {
        containerSpace: 'medium',
        paddingTop: '$72',
        paddingBottom: '$72',
        '@lg': {
          paddingTop: '$96',
          paddingBottom: '$96',
        },
      },
      doubleMedia: {
        containerSpace: 'medium',
        paddingTop: '$96',
        paddingBottom: '$96',
      },
      slider: {
        paddingTop: '$64',
        paddingBottom: '$64',
        containerSpace: 'none',
        paddingLeft: '$48',
        paddingRight: '$48',
        '@lg': {
          paddingLeft: '$64',
          paddingRight: '$64',
        },
      },
    },
  },
})

const BorderWrap = styled('div', {
  maxWidth: '50vw',
  width: '100%',
  variants: {
    mediaBorder: {
      none: {
        borderRadius: 'unset',
      },
      rounded: {
        overflow: 'hidden',
        transform: 'translateZ(0)',
        borderRadius: '$r3_mobile',
        '@lg': {
          borderRadius: '$r3',
        },
      },
    },
    appearance: {
      singleMedia: {
        maxWidth: 1100,
      },
      doubleMedia: {
        maxWidth: '40vw',
        '@lg': {
          maxWidth: '20vw',
        },
      },
      slider: {
        maxWidth: '50vw',
        '@lg': {
          maxWidth: '20vw',
        },
      },
    },
  },
})

export const MediaWithBackground: FC<MediaWithBackgroundProps> = ({
  media,
  mediaBorder,
  color,
  priority,
  sizes,
  appearance,
}) => {
  const { containerRef, videoControlProps, controlsProps } =
    useVideoOrchestrator({
      autoplay: true,
    })

  if (!media?.mediaPayload) {
    return null
  }
  return (
    <MediaPaddingWrap
      css={{
        backgroundColor: `${color && color.rgba}`,
      }}
      appearance={appearance}
    >
      <BorderWrap
        mediaBorder={mediaBorder}
        appearance={appearance}
        ref={containerRef}
      >
        <Media
          {...media}
          priority={priority}
          controlsPosition="none"
          sizes={sizes}
          videoOverride={(video) => (
            <Video
              alt={media.alt}
              firstFrame={video.firstFrame}
              width={video.width}
              height={video.height}
              src={video.mp4}
              layout="responsive"
              sizes={sizes}
              priority={priority}
              {...videoControlProps}
            />
          )}
          videoOverrideMobile={(video) => (
            <Video
              alt={media.alt}
              firstFrame={video.firstFrame}
              width={video.width}
              height={video.height}
              src={video.mp4}
              layout="responsive"
              sizes={sizes}
              priority={priority}
              {...videoControlProps}
            />
          )}
        />
      </BorderWrap>
      {media.mediaPayload.type === 'mux-video' && (
        <VideoControlButton {...controlsProps} />
      )}
    </MediaPaddingWrap>
  )
}
