export const ArrowNext = () => {
  return (
    <svg
      width="15"
      height="22"
      viewBox="0 0 15 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_5)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.29288 1.29288C3.68338 0.902375 4.31658 0.902375 4.70708 1.29288L14.9475 11.5333L4.70708 21.7737C4.31658 22.1643 3.68338 22.1643 3.29288 21.7737C2.90238 21.3832 2.90238 20.75 3.29288 20.3595L12.1191 11.5333L3.29288 2.70708C2.90238 2.31658 2.90238 1.68338 3.29288 1.29288Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_5">
          <rect width="15" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
