import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@portfolio/configs'

export const createSectionStatsProjection = (localeId: LocaleId) => {
  return groq`{
        _key,
        _type,
        stats[] {
          ${coalesceLocaleField('label', localeId)},
          value,
          symbol,
        }
      }
  `
}
