import { FC, PropsWithChildren } from 'react'
import { styled } from '@portfolio/styles'
import { Link } from '../Link/Link'
import {
  PortableText as SanityPortableText,
  PortableTextComponents,
  PortableTextMarkComponentProps,
} from '@portabletext/react'
import { TypedObject } from '@portabletext/types'
import { PortableTextBlocks } from '@portfolio/models'

type MarkComponent<T extends TypedObject = any> = (
  props: PropsWithChildren<PortableTextMarkComponentProps<T>>,
) => JSX.Element

export type PortableTextProps = {
  value?: PortableTextBlocks
  additionalComponents?: PortableTextComponents
  appearance?: 'default' | 'faq' | 'plain'
  // `disableTopBottomMargins` is handy when the PortableText is displayed in container with flex/grid which disables margin collapsing.
  disableTopBottomMargins?: boolean
}

const link: MarkComponent<{
  _type: string
  href: string
  linkType?: string
}> = ({ value, children }) => {
  return (
    <Link
      {...value}
      target={value?.linkType === 'external' ? '_blank' : undefined}
    >
      {children}
    </Link>
  )
}

export const PortableTextStylesWrapper = styled('div', {
  variants: {
    disableTopBottomMargins: {
      true: {
        '*:last-child': {
          marginBottom: 0,
        },
        '*:first-child': {
          marginTop: 0,
        },
      },
    },
    appearance: {
      default: {
        strong: {
          projectFont: 'body01_bold',
        },
        em: {
          fontStyle: 'italic',
        },
        projectFont: 'body01',
        b: {
          fontWeight: 'bold',
        },
        a: {
          textDecoration: 'underline',
          textUnderlineOffset: '5px',
          textDecorationThickness: '1px',
          '&:hover': {
            opacity: '0.5',
          },
        },
        h2: {
          marginBottom: '.75em',
          marginTop: '1.5em',
          projectFont: 'heading05',
        },
        h3: {
          marginTop: '1.5em',
          marginBottom: '.75em',
          projectFont: 'heading07',
        },
        blockquote: {
          projectFont: 'heading06',
          margin: '$64 0',
          textAlign: 'center',
        },
        'p, ul, ol': {
          marginTop: '1em',
          marginBottom: '1em',
          listStyle: 'inside',
        },
        'ul, ol': {
          paddingLeft: '$28',
        },
        ol: {
          listStyle: 'none',
          counterReset: 'ordered-list',
        },
        'ol > li': {
          position: 'relative',
          '&:before': {
            content: 'counter(ordered-list)"."',
            counterIncrement: 'ordered-list',
            position: 'absolute',
            right: '100%',
            projectFont: 'body01',
            marginRight: '8px',
          },
        },
        'ul > li': {
          listStyleType: 'none',
          marginBottom: '$16',
          marginTop: '$16',
          '&:before': {
            content: '–',
            position: 'absolute',
            marginLeft: '-$20',
          },
        },
      },
      faq: {
        projectFont: 'body03',
        strong: {
          projectFont: 'body03_bold',
        },
        em: {
          fontStyle: 'italic',
        },
        b: {
          fontWeight: 'bold',
        },
        a: {
          textDecoration: 'underline',
          textUnderlineOffset: '5px',
          textDecorationThickness: '1px',
          '&:hover': {
            opacity: '0.5',
          },
        },
        button: {
          projectFont: 'body03',
          textDecoration: 'underline',
          textUnderlineOffset: '5px',
          textDecorationThickness: '1px',
          '&:hover': {
            opacity: '0.5',
          },
        },
        h2: {
          marginBottom: '.75em',
          marginTop: '1.5em',
          projectFont: 'heading05',
        },
        h3: {
          marginTop: '1.5em',
          marginBottom: '.75em',
          projectFont: 'heading07',
        },
        blockquote: {
          projectFont: 'heading06',
          margin: '$64 0',
          textAlign: 'center',
        },
        'p, ul, ol': {
          marginTop: '1em',
          marginBottom: '1em',
          listStyle: 'inside',
        },
        ul: {
          paddingLeft: '$28',
        },
        'ul > li': {
          listStyleType: 'none',
          marginBottom: '$16',
          marginTop: '$16',

          '&:before': {
            content: '–',
            position: 'absolute',
            marginLeft: '-$20',
          },
        },
      },
      plain: {},
    },
  },
})

export const PortableText: FC<PortableTextProps> = ({
  value,
  additionalComponents,
  appearance = 'default',
  disableTopBottomMargins,
}) => {
  const components: PortableTextComponents = {
    ...additionalComponents,
    marks: {
      link,
      ...(additionalComponents?.marks ? additionalComponents.marks : {}),
    },
  }

  return value ? (
    <PortableTextStylesWrapper
      appearance={appearance}
      disableTopBottomMargins={disableTopBottomMargins}
    >
      <SanityPortableText value={value} components={components} />
    </PortableTextStylesWrapper>
  ) : null
}
