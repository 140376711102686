import { Action } from '@portfolio/models'
import React, { FC, ReactElement } from 'react'
import { captureException } from '@sentry/nextjs'
import { KlaviyoModalForm } from './KlaviyoModalForm'
import { ContactFormModal } from './ContactFormModal'

type LinkActionProps = {
  action: Action
  className?: string
  renderTrigger: (action: { click: () => void }) => ReactElement
}

export const LinkAction: FC<LinkActionProps> = ({ action, renderTrigger }) => {
  switch (action.type) {
    case 'klaviyoModalForm': {
      return (
        <KlaviyoModalForm
          desktopFormId={action.payload?.desktopFormId}
          mobileFormId={action.payload?.mobileFormId}
          renderTrigger={renderTrigger}
        />
      )
    }
    case 'contactModalForm': {
      return (
        <ContactFormModal
          socials={action.payload.socials}
          contactForm={action.payload.contactForm}
          renderTrigger={renderTrigger}
        />
      )
    }
    default:
      captureException(
        new Error(
          `[LinkAction] There is no defined actionType${
            (action as Action)?.type && `: ${(action as Action).type}`
          }`,
        ),
      )
      return <h3>There is no defined actionType.</h3>
  }
}
