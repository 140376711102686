import { LocaleId } from '@portfolio/configs'
import { getLocaleRegionIdFromPath } from '@portfolio/utils'
import { groq } from 'next-sanity'
import { createPageBuilderProjection } from './projections/createPageBuilderProjection'
import { getClient } from './getClient'
import { PageBuilderSection, PageResponse } from '@portfolio/models'
import { createCaseStudyPageProjection } from './projections/createCaseStudyPageProjection'
import { createArticlesLandingPageProjection } from './projections/createArticlesLandingPageProjection'
import { createArticlePageProjection } from './projections/createArticlePageProjection'

export const createPageByDocumentIdQuery = (
  documentId: string,
  localeId: LocaleId,
) => {
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)
  const CASE_STUDY_PAGE_PROJECTION = createCaseStudyPageProjection(localeId)
  const ARTICLES_LANDING_PAGE_PROJECTION =
    createArticlesLandingPageProjection(localeId)
  const CASE_ARTICLE_PAGE_PROJECTION = createArticlePageProjection(localeId)

  return groq`*[_id == "${documentId}"][0]{
    "page": select(
      _type == 'page' => {
        showFooter,
        ...,
        title,
        pageBuilder[]${PAGE_BUILDER_PROJECTION}
      },
      _type == "caseStudy" =>${CASE_STUDY_PAGE_PROJECTION},
      _type == "article" =>${CASE_ARTICLE_PAGE_PROJECTION},
      _type == "articlesLandingPage" =>${ARTICLES_LANDING_PAGE_PROJECTION},
    )
  }.page`
}

export const getPageByDocumentId = async (
  documentId: string,
  localeRegionString: string,
  preview = false,
): Promise<PageResponse<PageBuilderSection>> => {
  const [, localeId] = getLocaleRegionIdFromPath(localeRegionString)

  const query = createPageByDocumentIdQuery(documentId, localeId)

  const pageData = await getClient(preview).fetch(query)
  return pageData
}
