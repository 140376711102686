import { useMediaQuery } from '@portfolio/styles'
import { FC, ReactElement } from 'react'

declare global {
  interface Window {
    _klOnsite?: any[]
  }
}

export const KlaviyoModalForm: FC<{
  desktopFormId?: string
  mobileFormId?: string
  renderTrigger?: (action: { click: () => void }) => ReactElement
}> = ({ desktopFormId, mobileFormId, renderTrigger }) => {
  const isSmallDesktop = useMediaQuery('md')

  const handleClick = () => {
    window._klOnsite = window._klOnsite || []
    window._klOnsite.push([
      'openForm',
      isSmallDesktop ? desktopFormId : mobileFormId,
    ])
  }
  /**
   * This code is not working. The intent was to be able to pragmaticallly close the modal when breakpoint changes.
   * Leaving for reference for when Klaviyo introduces this option in the api
   */

  // useEffect(() => {
  //   console.log(isSmallDesktop)
  //   window._klOnsite = window._klOnsite || []
  //   window._klOnsite.push([
  //     'closeForm',
  //     isSmallDesktop ? mobileFormId : desktopFormId,
  //   ])
  // }, [isSmallDesktop, mobileFormId, desktopFormId])

  if (!desktopFormId || !mobileFormId || !renderTrigger) return null

  return renderTrigger({
    click: handleClick,
  })
}
