import { groq } from 'next-sanity'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { sanityImageProjection } from './createMediaProjection'

export const createBaseImageProjection = (localeId: string) => groq`{
    ${coalesceLocaleField('alt', localeId)},
     "mediaPayload": {
        "type": "sanity-image",
        "image": ${sanityImageProjection('image')},
      }
   }`
