import { keyframes, styled } from '@portfolio/styles'
import {
  Root as RadixRoot,
  Item as RadixItem,
  Trigger as RadixTrigger,
  Content as RadixContent,
} from '@radix-ui/react-accordion'
import { ReactNode } from 'react'

interface AccordionProps {
  children: ReactNode
}

function Accordion({ children }: AccordionProps) {
  return (
    <RadixRoot type="single" collapsible>
      {children}
    </RadixRoot>
  )
}

interface AccordionItemProps {
  children: ReactNode
  value: string
}

function AccordionItem({ children, value }: AccordionItemProps) {
  return <Item value={value}>{children}</Item>
}

const Item = styled(RadixItem, {
  borderTop: '1px solid #2D2D2D',

  '&:last-child': {
    borderBottom: '1px solid #2D2D2D',
  },
})

interface AccordionTriggerProps {
  children: ReactNode
}

function AccordionTrigger({ children }: AccordionTriggerProps) {
  return (
    <Trigger>
      {children}
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.6562 24.2939L11.6562 0.606445L12.6562 0.606445L12.6562 24.2939H11.6562Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.6562 24.2939L11.6562 0.606445L12.6562 0.606445L12.6562 24.2939H11.6562Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.3125 11.9502L24 11.9502V12.9502L0.3125 12.9502L0.3125 11.9502Z"
          fill="currentColor"
        />
      </svg>
    </Trigger>
  )
}

const Trigger = styled(RadixTrigger, {
  display: 'flex',
  gap: 24,
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: '100%',
  paddingBlock: 24,
  projectFont: 'heading07',
  color: '$white',
  textAlign: 'left',
  backgroundColor: 'transparent',

  ' svg': {
    flexShrink: 0,
    transition: 'transform 0.3s ease-out',
  },

  '&[data-state="open"] svg': {
    transform: 'rotate(-45deg)',
  },
})

interface AccordionContentProps {
  children: ReactNode
}

function AccordionContent({ children }: AccordionContentProps) {
  return (
    <Content>
      <ContentInnerWrapper>{children}</ContentInnerWrapper>
    </Content>
  )
}

const slideDown = keyframes({
  from: {
    gridTemplateRows: '0fr',
  },
  to: {
    gridTemplateRows: '1fr',
  },
})

const slideUp = keyframes({
  from: {
    gridTemplateRows: '1fr',
  },
  to: {
    gridTemplateRows: '0fr',
  },
})

const Content = styled(RadixContent, {
  display: 'grid',
  projectFont: 'body03',
  color: '$white_08',

  '&[data-state="open"]': {
    animation: `${slideDown} 0.5s cubic-bezier(0, 0, 0, 1.14)`,
  },

  '&[data-state="closed"]': {
    animation: `${slideUp} 0.5s cubic-bezier(0, 0, 0, 1.14)`,
  },
})

const ContentInnerWrapper = styled('div', {
  overflow: 'hidden',
})

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent }
