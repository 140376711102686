import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@portfolio/configs'
import { createBaseImageProjection } from '../projections/createBaseImageProjection'
import { createRichTextProjection } from '../projections/createRichTextProjection'
import { createLinkProjection } from '../projections/createLinkProjection'

export const createSectionNewsletterProjection = (localeId: LocaleId) => {
  const BASE_IMAGE_PROJECTION = createBaseImageProjection(localeId)
  const RICH_TEXT_PROJECTION = createRichTextProjection(localeId)
  const LINK_PROJECTION = createLinkProjection(localeId)
  return groq`{
        _key,
        _type,
        theme,
        ${coalesceLocaleField('title', localeId)},
        ${coalesceLocaleField('description', localeId)},
        authors[]->{
          name,
          avatar${BASE_IMAGE_PROJECTION},
          ${coalesceLocaleField(
            'description',
            localeId,
          )}[]${RICH_TEXT_PROJECTION},
          ${coalesceLocaleField('position', localeId)},
          ${coalesceLocaleField('linkedin', localeId)},
        },
        ${coalesceLocaleField('ctaLabel', localeId)},
        ctaLink${LINK_PROJECTION},
      }
  `
}
