import { colors } from '@portfolio/styles'

type ApproveProps = {
  color?: 'gray' | 'green'
  size?: 'small' | 'medium'
}

export const Approve = ({ color = 'gray', size = 'small' }: ApproveProps) => {
  const colorResolver = (color: 'gray' | 'green') => {
    switch (color) {
      case 'gray':
        return { color: colors.white, fillOpacity: 0.6 }
      case 'green':
        return { color: colors.successGreen, fillOpacity: 1 }
      default:
        return { color: colors.white, fillOpacity: 1 }
    }
  }

  const sizeResolver = (size: 'small' | 'medium') => {
    switch (size) {
      case 'small':
        return 13
      case 'medium':
        return 16
      default:
        return 13
    }
  }

  const resolvedColor = colorResolver(color)
  const resolvedSize = sizeResolver(size)

  return (
    <svg
      width={resolvedSize}
      height={resolvedSize}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 6.3999C11.25 9.16133 9.01142 11.3999 6.25 11.3999C3.48858 11.3999 1.25 9.16133 1.25 6.3999C1.25 3.63848 3.48858 1.3999 6.25 1.3999C9.01142 1.3999 11.25 3.63848 11.25 6.3999ZM10.45 6.3999C10.45 8.7195 8.5696 10.5999 6.25 10.5999C3.9304 10.5999 2.05 8.7195 2.05 6.3999C2.05 4.08031 3.9304 2.1999 6.25 2.1999C8.5696 2.1999 10.45 4.08031 10.45 6.3999Z"
        fill={resolvedColor.color}
        fillOpacity={resolvedColor.fillOpacity}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.8631 7.15619L8.22388 4.79541L8.78956 5.3611L5.8631 8.28756L3.71094 6.1354L4.27662 5.56972L5.8631 7.15619Z"
        fill={resolvedColor.color}
        fillOpacity={resolvedColor.fillOpacity}
      />
    </svg>
  )
}
