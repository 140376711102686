import { styled } from '@portfolio/styles'
import ReactMuxPlayer from '@mux/mux-player-react'
import { useInView } from 'react-intersection-observer'

function MuxPlayer({
  playbackId,
  poster,
  isAutoPlay,
  intersectionObserverOptions,
}: {
  playbackId: string
  poster?: string
  isAutoPlay?: boolean
  intersectionObserverOptions?: {
    rootMargin?: string
  }
}) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: intersectionObserverOptions?.rootMargin,
  })

  return (
    <StyledMuxPlayer
      playbackId={playbackId}
      poster={poster}
      streamType="on-demand"
      muted
      autoPlay={isAutoPlay && inView}
      ref={ref}
    />
  )
}

export { MuxPlayer }

const StyledMuxPlayer = styled(ReactMuxPlayer, {
  '--seek-backward-button': 'none',
  '--seek-forward-button': 'none',
  '--pip-button': 'none',
  '--playback-rate-button': 'none',
  '--controls-backdrop-color': 'rgb(0 0 0 / 45%)',
})
