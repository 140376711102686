import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@portfolio/configs'
import { createBaseImageProjection } from '../projections/createBaseImageProjection'

export const createSectionHomeHeroProjection = (localeId: LocaleId) => {
  return groq`{
        _key,
        _type,
        textTheme,
        ${coalesceLocaleField('text', localeId)},
        technologiesRows[] {
          items[]${createBaseImageProjection(localeId)},
        },
      }
  `
}
