/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

(window ).__rewriteFramesAssetPrefixPath__ = '';

import { GLOBAL_OBJ } from '@sentry/utils';

/* eslint-disable no-constant-condition */

const globalObj = GLOBAL_OBJ ;

globalObj.SENTRY_RELEASE = { id: '70b7bf08329e2594f5b7c59ddff81c16a6fe3a9f' };

// Enable module federation support (see https://github.com/getsentry/sentry-webpack-plugin/pull/307)
if ('portfolio') {
  const key = 'commerce-ui-dev' ? 'portfolio@commerce-ui-dev' : 'portfolio';
  globalObj.SENTRY_RELEASES = globalObj.SENTRY_RELEASES || {};
  globalObj.SENTRY_RELEASES[key] = { id: '70b7bf08329e2594f5b7c59ddff81c16a6fe3a9f' };
}

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

const enabledContexts = ['production', 'deploy-preview']

Sentry.init({
  environment: process.env.SENTRY_ENVIRONMENT,
  dsn:
    SENTRY_DSN ||
    'https://adf4888f77e84bb9a2c8f0f396367ed2@o1220134.ingest.sentry.io/6749938',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.5,
  // Sentry will not run on localhost
  enabled: enabledContexts.includes(process.env.SENTRY_ENVIRONMENT),
  // Set up integrations
  // https://docs.sentry.io/platforms/javascript/configuration/integrations/plugin/
  integrations: [
    new CaptureConsoleIntegration({
      // array of methods that should be captured
      // "log" not included
      levels: ['error'],
    }),
  ],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
