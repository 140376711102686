import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@portfolio/configs'
import { createMediaProjection } from '../projections/createMediaProjection'

export const createSectionProjectCardProjection = (localeId: LocaleId) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId, 'HIGH')

  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('text', localeId)},
        media${MEDIA_PROJECTION},
        caseStudy->{
          name,
          "path":path.current,
          "services": servicesReferences[]->{
            "title": coalesce(title.en, title.en)
          }.title
       }
      }
  `
}
