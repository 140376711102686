import { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { styled } from '@portfolio/styles'
import { Button } from '../Button/Button'
import { ArrowNext, ArrowPrev } from '@portfolio/icons'

type SliderProps = {
  children?: ReactNode[]
  isVideo?: boolean
}
const StyledButton = styled(Button, {
  display: 'none',

  '@md': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '45%',
    transform: 'translateY(-45%)',
    zIndex: '$sliderArrows',
    width: '$56',
    height: '$56',
    borderRadius: '$rMax',
    transition: '0.2s background',

    '&:nth-child(1)': {
      left: '$56',
    },

    '&:nth-child(2)': {
      right: '$56',
    },
    background: '$gray500_02',
    backdropFilter: 'blur(10px)',
    '&:hover': {
      background: '$gray500_03',
    },
    '&:active': {
      background: '$gray500_04',
    },

    '&:focus-visible': {
      outline: '$focusBlue solid 3px;',
    },

    '&:disabled': {
      display: 'none',
    },
  },
})

const SliderWrapper = styled('div', {
  overflowX: 'hidden',
  position: 'relative',
  [`& ${StyledButton}`]: {
    opacity: 0,
    transition:
      'transform 0.4s ease-in, opacity 0.5s cubic-bezier(0.15, 0, 0.2, 1) 0.1s',
    transform: 'scale(0.8)',
  },
  '&:hover': {
    [`& ${StyledButton}`]: {
      opacity: 1,
      transition:
        'transform 0.4s ease-in, opacity 0.5s cubic-bezier(0.15, 0, 0.2, 1) 0.1s',
      transform: 'scale(1)',
    },
  },
})

const StrictSlider = styled('div', {
  display: 'grid',
  position: 'relative',
  width: '100vw',
  overflowY: 'hidden',
  overflowX: 'auto',
  scrollSnapType: 'x mandatory',
  containerSpace: 'medium',

  lin: {
    scrollPaddingLeft: 'M',
    scrollPaddingRight: 'M',
  },

  '&::-webkit-scrollbar': {
    display: 'none',
  },
})

const Track = styled('div', {
  position: 'relative',
  display: 'grid',
  gridAutoFlow: 'column',
  gridColumnGap: '$20',
  margin: '0 auto',

  '& > *': {
    scrollSnapAlign: 'start',
  },
  '@lg': {
    gridColumnGap: '$32',

    '& > *': {
      scrollSnapAlign: 'start',
    },
  },

  variants: {
    isVideo: {
      true: {
        gridAutoColumns: '224px',
        '& > *': {
          scrollSnapAlign: 'center',
        },
        '@md': {
          gridAutoColumns: '300px',
        },

        '@lg': {
          gridAutoColumns: '340px',
        },
      },

      false: {
        gridAutoColumns: 'calc(100vw - 40px)',
        gridRowGap: '$20',
        '@lg': {
          gridAutoColumns: '486px',
        },
      },
    },
  },
})

export const Slider: FC<SliderProps> = ({ children, isVideo = false }) => {
  const sliderRef = useRef<null | HTMLDivElement>(null)
  const trackRef = useRef<null | HTMLDivElement>(null)

  const [isFirstVisible, setIsFirstVisible] = useState<boolean>(true)
  const [isLastVisible, setIsLastVisible] = useState<boolean>(false)

  const scrollSlides = (slidesToScroll: number) => {
    const sliderElem = sliderRef.current
    if (!sliderElem) return
    const slide1 = sliderElem.firstChild?.childNodes[0] as Element
    const slide2 = sliderElem.firstChild?.childNodes[1] as Element
    if (!slide1 || !slide2) return
    const slideWidth = slide1.getBoundingClientRect().width
    const gutterWidth =
      slide2.getBoundingClientRect().left - slide1.getBoundingClientRect().right
    const destination =
      slidesToScroll * slideWidth + slidesToScroll * gutterWidth
    sliderElem.scrollBy({
      left: destination,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    if (!children) return
    if (!sliderRef.current) return

    const firstSlide = sliderRef.current?.firstChild?.firstChild as Element
    const lastSlide = sliderRef.current?.firstChild?.lastChild as Element

    const observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        const entry = entries[0]

        if (entry.target === firstSlide) {
          setIsFirstVisible(entry.isIntersecting)
        }
        if (entry.target === lastSlide) {
          setIsLastVisible(entry.isIntersecting)
        }
      },
      { root: sliderRef.current, rootMargin: '0%', threshold: 1 },
    )

    lastSlide && observer.observe(lastSlide)
    firstSlide && observer.observe(firstSlide)

    return () => {
      observer.unobserve(firstSlide)
      observer.unobserve(lastSlide)
    }
  }, [sliderRef])

  return (
    <SliderWrapper>
      <StyledButton
        onClick={() => scrollSlides(-1)}
        disabled={isFirstVisible}
        aria-label="previous"
        appearance="default"
      >
        <ArrowPrev />
      </StyledButton>

      <StyledButton
        onClick={() => scrollSlides(1)}
        disabled={isLastVisible}
        aria-label="next"
        appearance="default"
      >
        <ArrowNext />
      </StyledButton>
      <StrictSlider ref={sliderRef}>
        <Track ref={trackRef} isVideo={isVideo}>
          {children}
        </Track>
      </StrictSlider>
    </SliderWrapper>
  )
}
