import { EmbedProps } from '@portfolio/models'
import { styled, useMediaQuery } from '@portfolio/styles'
import { useState } from 'react'
import { Link } from '../Link/Link'

const LinkedInIframe = styled('iframe', {
  width: '100%',
  variants: {
    loaded: {
      true: {
        display: 'block',
      },
      false: {
        display: 'none',
      },
    },
  },
})

const Loader = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '$8',
  justifyContent: 'center',
  alignItems: 'center',
  font: 'caption',
})

const LoaderLink = styled(Link, {
  display: 'block',
  width: '100%',
  height: '100%',
  textAlign: 'center',
})

const LinkedIn = ({
  url,
  desktopHeight = '500',
  mobileHeight = '500',
  postUrl,
}: Pick<EmbedProps, 'url' | 'postUrl' | 'desktopHeight' | 'mobileHeight'>) => {
  const isMd = useMediaQuery('md')
  const [loaded, setLoaded] = useState(false)
  const height = (isMd ? desktopHeight : mobileHeight) + 'px'

  if (!url) return null

  return (
    <>
      {!loaded && (
        <LoaderLink href={postUrl} target="_blank">
          <Loader style={{ height }}>
            <p>Loading...</p>
            <p>View post on LinkedIn</p>
          </Loader>
        </LoaderLink>
      )}
      <LinkedInIframe
        src={url}
        height={isMd ? desktopHeight + 'px' : mobileHeight + 'px'}
        loaded={loaded}
        onLoad={() => setLoaded(true)}
      />
    </>
  )
}

export const Embed = ({
  embedType,
  url,
  desktopHeight,
  mobileHeight,
  postUrl,
}: Pick<
  EmbedProps,
  'url' | 'postUrl' | 'embedType' | 'desktopHeight' | 'mobileHeight'
>) => {
  switch (embedType) {
    case 'linkedin':
      return (
        <LinkedIn
          url={url}
          desktopHeight={desktopHeight}
          mobileHeight={mobileHeight}
          postUrl={postUrl}
        />
      )
    default:
      console.error(`Unsupported embed type: ${embedType}`)
      return null
  }
}
