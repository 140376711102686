import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@portfolio/configs'
import { createMediaProjection } from '../projections/createMediaProjection'
import { createBaseImageProjection } from '../projections/createBaseImageProjection'

export const createSectionQuoteWithVideoProjection = (localeId: LocaleId) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId, 'MEDIUM')
  const BASE_IMAGE_PROJECTION = createBaseImageProjection(localeId)

  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('quote', localeId)},
        ${coalesceLocaleField('personName', localeId)},
        ${coalesceLocaleField('personPosition', localeId)},
        logo${BASE_IMAGE_PROJECTION},
        media${MEDIA_PROJECTION}
  }
  `
}
