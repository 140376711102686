import { FC, useEffect, useState } from 'react'
import { SanityImageMediaPayload, SiteConfiguration } from '@portfolio/models'
import { styled } from '@portfolio/styles'
import { Button, Container, Link, Media, Modal } from '@portfolio/components'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/dist/client/router'
import { Logo } from '@portfolio/icons'
import { MobileDrawer } from './MobileDrawer'
type Props = {
  header: SiteConfiguration['header']
  theme: 'transparent-dark' | 'transparent-light'
  position: 'fixed' | 'absolute'
}

const StyledContainer = styled(Container, {
  zIndex: '$header',
  height: '$headerHeight',
  position: 'absolute',
  projectFont: 'body03',
  top: 0,
  left: 0,
  display: 'grid',
  justifyContent: 'space-between',
  gap: '$24',
  gridAutoFlow: 'column',
  alignItems: 'center',

  variants: {
    theme: {
      'transparent-dark': {
        color: '$black',
      },
      'transparent-light': {
        color: '$white',
      },
    },
    position: {
      absolute: {
        position: 'absolute',
      },
      fixed: {
        position: 'fixed',
      },
    },
  },
})
const LogoWrapper = styled(Link, {
  width: '100%',
  borderRadius: '$r1',
  '&:focus-visible': {
    outline: '$focusBlue solid 3px;',
    outlineOffset: '8px',
  },
})
const NavigationWrap = styled('ul', {
  height: '100%',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gridAutoFlow: 'column',
  gap: '$48',
  display: 'none',
  '@lg': {
    display: 'grid',
  },
})
const NavigationItem = styled('li', {
  display: 'grid',
  gridGap: '$12',
  gridAutoFlow: 'column',
  alignItems: 'center',
})

const NavigationItemIcon = styled('span', {
  width: '$20',
  height: '$20',
  display: 'grid',
  alignSelf: 'center',
  margin: '$0 $6 0 0',
})

const MobileNav = styled(Button, {
  display: 'grid',
  justifyItems: 'flex-end',
  alignItems: 'center',
  projectFont: 'body03',
  color: 'currentColor',
  '@lg': {
    display: 'none',
  },
})

const StyledLink = styled(Link, {
  color: 'currentcolor',
  opacity: 0.5,
  transition: '0.2s opacity',
  '@lg': {
    '&:hover': {
      opacity: 1,
    },
  },
  '&:active': {
    opacity: 1,
  },
  '&:focus-visible': {
    outline: '$focusBlue solid 3px;',
  },
  variants: {
    currentPage: {
      true: {
        opacity: 1,
      },
    },
  },
})

export const Header: FC<Props> = ({ header, theme, position }) => {
  const router = useRouter()
  const { t } = useTranslation('header')
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

  useEffect(() => {
    const handleRouteChange = () => {
      // close mobile nav
      setIsMobileMenuOpen(false)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [setIsMobileMenuOpen, isMobileMenuOpen, router.events])

  return (
    <StyledContainer theme={theme} position={position}>
      <LogoWrapper appearance="grid" href="/" title="Commerce-ui logo">
        <Logo theme={theme} />
      </LogoWrapper>
      <nav>
        <NavigationWrap>
          {header?.navLinks &&
            header?.navLinks?.length > 0 &&
            header?.navLinks?.map((navItem, index) => (
              <NavigationItem key={`${navItem?.ctaLabel}-${index}`}>
                {navItem.ctaLabel && navItem?.ctaLink && (
                  <StyledLink
                    currentPage={
                      (navItem.ctaLink.href &&
                        router.asPath.startsWith(navItem?.ctaLink?.href)) ||
                      router.asPath === '/'
                    }
                    appearance="grid"
                    {...navItem.ctaLink}
                  >
                    {navItem.ctaIcon?.mediaPayload &&
                      (navItem.ctaIcon.mediaPayload as SanityImageMediaPayload)
                        .image.asset && (
                        <NavigationItemIcon>
                          <Media
                            {...navItem.ctaIcon}
                            priority={false}
                            sizes={'40px'}
                          />
                        </NavigationItemIcon>
                      )}
                    {navItem.ctaLabel}
                  </StyledLink>
                )}
              </NavigationItem>
            ))}
        </NavigationWrap>
      </nav>
      <MobileNav
        appearance="text"
        onClick={() => {
          setIsMobileMenuOpen(true)
        }}
      >
        {t('menu')}
      </MobileNav>
      <Modal
        preventScroll={true}
        isOpen={isMobileMenuOpen}
        onRequestClose={() => setIsMobileMenuOpen(false)}
        size={'stretch'}
      >
        <MobileDrawer
          onCloseClick={() => setIsMobileMenuOpen(false)}
          header={header}
          theme="transparent-light"
        />
      </Modal>
    </StyledContainer>
  )
}
