export const Close = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.83107 9.58305L14.1951 15.9471L15.6093 14.5329L9.24528 8.16884L15.7782 1.63595L14.364 0.221741L7.83107 6.75462L1.46719 0.390746L0.0529785 1.80496L6.41686 8.16884L0.221816 14.3639L1.63603 15.7781L7.83107 9.58305Z"
        fill="black"
      />
    </svg>
  )
}
