import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import TRANSLATIONS_EN_US from './locales/en-US.json'

export default i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: TRANSLATIONS_EN_US,
    },
    fallbackLng: 'en',
    lng: 'en',
  })
