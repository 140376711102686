import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@portfolio/configs'
import { createMediaProjection } from '../projections/createMediaProjection'
import { createLinkProjection } from '../projections/createLinkProjection'

export const createSectionTechstackSliderProjection = (localeId: LocaleId) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const LINK_PROJECTION = createLinkProjection(localeId)

  return groq`{
      _type,
      ${coalesceLocaleField('title', localeId)},
      slides[] {
        _key,
        logo${MEDIA_PROJECTION},
        ${coalesceLocaleField('description', localeId)},
        "elements": elements[] {
          _type == 'element' => {
            "text": coalesce(de, en)
          }
        }.text,
        "footerBlocks": footer[]{
          _type,
          _type == 'linkBlock' => {
            _key,
            logo${MEDIA_PROJECTION},
            ${coalesceLocaleField('content', localeId)},
            cta${LINK_PROJECTION},
            ${coalesceLocaleField('ctaLabel', localeId)},
          },
          _type == 'quoteBlock' => {
            _key,
            image${MEDIA_PROJECTION},
            name,
            ${coalesceLocaleField('role', localeId)},
            ${coalesceLocaleField('quote', localeId)},
          },
        }
      }
    }
  `
}
