import { FC } from 'react'
import { SanityImage } from './components/SanityImage'
import { MuxVideo } from './components/MuxVideo'
import { styled } from '@portfolio/styles'
import { MediaProps } from './models/MediaProps'

const MediaFillWrap = styled('div', {
  position: 'relative',
  variants: {
    fill: {
      true: {
        height: '100%',
        width: '100%',
      },
    },
    disableBackground: {
      true: {
        backgroundColor: 'transparent',
      },
      false: {},
    },
  },
})

export const Media: FC<MediaProps> = ({
  layout = 'responsive',
  objectFit = 'cover',
  sizes,
  priority,
  mediaPayload,
  alt,
  loop,
  autoplay,
  muted,
  hardcropRatio,
  controlsPosition,
  videoOverride,
  videoOverrideMobile,
  disablePlaceholderBackground,
}) => {
  switch (mediaPayload?.type) {
    case 'sanity-image':
      return (
        <MediaFillWrap
          fill={layout === 'fill'}
          disableBackground={
            disablePlaceholderBackground ?? mediaPayload.image.unoptimized
          }
        >
          <SanityImage
            alt={alt}
            mediaPayload={mediaPayload}
            sizes={sizes}
            objectFit={objectFit}
            priority={priority}
            layout={layout}
            hardcropRatio={hardcropRatio}
            blur
          />
        </MediaFillWrap>
      )
    case 'mux-video':
      return (
        <MediaFillWrap
          fill={layout === 'fill'}
          disableBackground={disablePlaceholderBackground}
        >
          <MuxVideo
            alt={alt}
            mediaPayload={mediaPayload}
            layout={layout}
            priority={priority}
            loop={loop}
            sizes={sizes}
            muted={muted}
            controlsPosition={controlsPosition}
            autoplay={autoplay}
            videoOverride={videoOverride}
            videoOverrideMobile={videoOverrideMobile}
            hardcropRatio={hardcropRatio}
          />
        </MediaFillWrap>
      )
    default:
      return (
        <MediaFillWrap fill={layout === 'fill'}>
          <div>Unsupported media source</div>
        </MediaFillWrap>
      )
  }
}
