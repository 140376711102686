import { createSanityColorProjection } from './../projections/createSanityColorProjecton'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@portfolio/configs'
import { createMediaProjection } from '../projections/createMediaProjection'

export const createSectionSingleMediaWithDescriptionProjection = (
  localeId: LocaleId,
) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const COLOR_PROJECTION = createSanityColorProjection()
  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('mediaDescription', localeId)},
        mode,
        mediaField[0]{
          _type == 'simpleMedia' => {
            _type,
            media${MEDIA_PROJECTION}
          },
          _type == 'mediaWithBackground' => {
            _type,
            media${MEDIA_PROJECTION},
            mediaBorder,
            color${COLOR_PROJECTION},
        }
      }
    }
  `
}
