import { imageUrlBuilder } from '@portfolio/services/sanity-service'
import { MediaFieldType } from '@portfolio/models'

export const seoImageLoader = (
  media?: MediaFieldType,
  altText?: string,
): { url: string; width: number; height: number; alt: string } | null => {
  const type = media?.mediaPayload?.type

  if (!type) {
    return null
  }

  const alt = altText || media.alt
  const width = 1200
  const height = 630

  switch (type) {
    case 'sanity-image':
      if (!media.mediaPayload.image?.asset) {
        return null
      }
      return {
        url: imageUrlBuilder
          .image(media.mediaPayload.image)
          .width(width)
          .height(height)
          .url(),
        width,
        height,
        alt: alt || '',
      }

    default:
      return null
  }
}
