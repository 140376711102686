import { Socials as SocialsProps } from '@portfolio/models'
import { styled } from '@portfolio/styles'
import React, { FC } from 'react'
import { Link } from '../Link/Link'
import { Media } from '../Media/Media'

const SocialsWrap = styled('div', {
  display: 'flex',
  gap: '$16',
  justifySelf: 'end',
})
const SocialButton = styled(Link, {
  width: '$36',
  height: '$36',
  alignItems: 'center',
  justifyItems: 'center',
  display: 'grid',
  borderRadius: '$rMax',
  variants: {
    iconsColor: {
      light: {
        background: '$white_02',
        '@lg': {
          '&:hover': {
            background: '$white_03',
          },
        },
      },
      dark: {
        background: '$socialsGray',

        '@lg': {
          '&:hover': {
            background: '$gray500_02',
          },
        },
      },
    },
  },

  '&:active': {
    background: '$white_024',
  },
  '&:focus-visible': {
    outline: '$focusBlue solid 3px;',
  },
})
const IconWrap = styled('div', {
  width: '$16',
  height: '$16',
  display: 'grid',
  fill: '$white',
  color: '$white',
})
export const Socials: FC<SocialsProps> = ({
  items,
  iconsColor = 'light',
  priority,
}) => {
  return (
    <SocialsWrap>
      {items &&
        items.length > 0 &&
        items?.map((social, index) => (
          <SocialButton
            target="_blank"
            appearance="grid"
            iconsColor={iconsColor}
            href={social.url}
            key={`social-${index}`}
          >
            {iconsColor === 'light' ? (
              <IconWrap>
                {social.logoLight?.mediaPayload && (
                  <Media
                    {...social.logoLight}
                    priority={priority}
                    sizes={'20px'}
                  />
                )}
              </IconWrap>
            ) : (
              <IconWrap>
                {social.logoDark?.mediaPayload && (
                  <Media
                    {...social.logoDark}
                    priority={priority}
                    sizes={'20px'}
                  />
                )}
              </IconWrap>
            )}
          </SocialButton>
        ))}
    </SocialsWrap>
  )
}
