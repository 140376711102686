import { ContainerSpaceType } from '@portfolio/models'
import { styled } from '@portfolio/styles'
import React, { forwardRef, PropsWithChildren } from 'react'

const Wrap = styled('div', {
  width: '100%',
  variants: {
    spaceSize: {
      none: {
        containerSpace: 'none',
      },
      small: {
        containerSpace: 'small',
      },
      medium: {
        containerSpace: 'medium',
      },
      large: {
        containerSpace: 'large',
      },
    },
  },
})

type Props = PropsWithChildren<{
  id?: string
  className?: string
  spaceSize?: ContainerSpaceType
  as?: keyof HTMLElementTagNameMap
}>

export const Container = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    children,
    className,
    spaceSize = 'medium',
    as = 'div',
    id = '',
  } = props
  return (
    <Wrap ref={ref} className={className} spaceSize={spaceSize} as={as} id={id}>
      {children}
    </Wrap>
  )
})
