import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@portfolio/configs'

export const createSectionTextBlocksProjection = (localeId: LocaleId) => {
  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('title', localeId)},
        textBlocks[] {
           ${coalesceLocaleField('title', localeId)},
           ${coalesceLocaleField('text', localeId)},
        }
      }
  `
}
