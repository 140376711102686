import { createStitches } from '@stitches/react'
import type * as Stitches from '@stitches/react'
import { sizes } from './sizes'
import { breakpoints } from './breakpoints'
import { colors } from './colors'
import { lin } from './lin'
import { radius } from './radius'
import { getFontStyles } from './fonts'
import { getContainerSpace } from './containerSpace'

const stitches = createStitches({
  theme: {
    colors,
    /**
     * Custom radius values
     * example:
     * 'r1': '6px',
     *  -> borderRadius: '$r1' // maps to theme.radii.1
     */
    radii: radius,
    space: sizes,
    sizes,
    zIndices: {
      header: 100,
      content: 80,
      cardButton: 85,
      sliderArrows: 90,
      videoControls: 90,
    },
  },
  media: breakpoints,
  /**
   * Custom utilities
   * https://stitches.dev/docs/utils
   * example:
   * m: (value: string) => ({ margin: value }),
   */
  utils: {
    containerSpace: getContainerSpace,
    projectFont: getFontStyles,
    lin,
    mx: (value: string) => ({ marginLeft: value, marginRight: value }),
  },
})

export type CSS = Stitches.CSS<typeof stitches.config>

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = stitches
