export const ratios = {
  square: 1,
  landscape1: 0.67,
  landscape2: 0.8,
  landscape3: 0.5,
  portrait1: 1.25,
  portrait2: 1.67,
  portrait3: 1.4,
}

export type ImageSizes =
  | '420'
  | '768'
  | '1024'
  | '1400'
  | '1600'
  | '1920'
  | '2560'

export type MediaRatiosType = keyof typeof ratios

export type MediaRatiosTypeExtended =
  | MediaRatiosType
  | Record<ImageSizes, keyof typeof ratios>
