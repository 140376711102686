export const Error = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.30056 5.8484H4.69612L4.63422 2.33826H5.36246L5.30056 5.8484Z"
        fill="#F82E47"
      />
      <path
        d="M5.36246 7.51972C5.27022 7.61439 5.15006 7.66173 5.00198 7.66173C4.8539 7.66173 4.73253 7.61439 4.63786 7.51972C4.54319 7.42505 4.49585 7.30368 4.49585 7.1556C4.49585 7.0051 4.54319 6.88251 4.63786 6.78784C4.73253 6.69316 4.8539 6.64583 5.00198 6.64583C5.15006 6.64583 5.27022 6.69316 5.36246 6.78784C5.45713 6.88251 5.50447 7.0051 5.50447 7.1556C5.50447 7.30368 5.45713 7.42505 5.36246 7.51972Z"
        fill="#F82E47"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5ZM9.2 5C9.2 7.3196 7.3196 9.2 5 9.2C2.6804 9.2 0.8 7.3196 0.8 5C0.8 2.6804 2.6804 0.8 5 0.8C7.3196 0.8 9.2 2.6804 9.2 5Z"
        fill="#F82E47"
      />
    </svg>
  )
}
