import { createArticlePageProjection } from './projections/createArticlePageProjection'
import { createArticlesLandingPageProjection } from './projections/createArticlesLandingPageProjection'
import { createCaseStudyPageProjection } from './projections/createCaseStudyPageProjection'
import { getLocaleRegionIdFromPath } from '@portfolio/utils'
import { groq } from 'next-sanity'
import { getClient } from './getClient'
import { PageBuilderSection, PageResponse } from '@portfolio/models'
import { createDefaultPageProjection } from './projections/createDefaultPageProjection'

export const getRouteByPath = async (
  path: string,
  localeRegionString: string,
  preview = false,
): Promise<PageResponse<PageBuilderSection[]>> => {
  const [regionId, localeId] = getLocaleRegionIdFromPath(localeRegionString)
  const pageQuery = groq`*[!(_id in path('drafts.**')) && defined(path.current) && path.current == "${path}"][0]{
    _type
  }`

  const page = await getClient(preview).fetch(pageQuery)
  const pageProjection = getPageProjectionByType(page._type, regionId, localeId)

  const query = groq`*[!(_id in path('drafts.**')) && defined(path.current) && path.current == "${path}"][0]{
    "page": {...${pageProjection}}
  }.page`

  return await getClient(preview).fetch(query)
}

const getPageProjectionByType = (
  pageType: string,
  regionId: string,
  localeId: string,
) => {
  const DEFAULT_PAGE_PROJECTION = createDefaultPageProjection(
    regionId,
    localeId,
  )
  const CASE_STUDY_PAGE_PROJECTION = createCaseStudyPageProjection(localeId)
  const ARTICLES_LANDING_PAGE_PROJECTION =
    createArticlesLandingPageProjection(localeId)
  const CASE_ARTICLE_PAGE_PROJECTION = createArticlePageProjection(localeId)
  let pageProjection = ''
  switch (pageType) {
    case 'route':
      pageProjection = DEFAULT_PAGE_PROJECTION
      break
    case 'caseStudy':
      pageProjection = CASE_STUDY_PAGE_PROJECTION
      break
    case 'article':
      pageProjection = CASE_ARTICLE_PAGE_PROJECTION
      break
    case 'articlesLandingPage':
      pageProjection = ARTICLES_LANDING_PAGE_PROJECTION
      break
    default:
      break
  }
  return pageProjection
}
