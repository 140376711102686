import {
  LinkFieldType,
  MediaFieldType,
  MuxVideoType,
  PortableTextBlocks,
} from '@portfolio/models'
import { getLocaleRegionIdFromPath } from '@portfolio/utils'
import { groq } from 'next-sanity'
import { getClient } from './getClient'
import { coalesceLocaleField } from './helpers/coalesceLocaleField'
import { createLinkProjection } from './projections/createLinkProjection'
import {
  SELECTED_STATIC_RENDITION,
  createMediaProjection,
} from './projections/createMediaProjection'
import { createRichTextProjection } from './projections/createRichTextProjection'
import { createSEOProjection } from './projections/createSEOProjection'
import { Seo } from '@portfolio/models'

export type HydrogenPageResult = {
  heroSection: {
    title: string
    description: string
    image: MediaFieldType
    action: {
      label: string
      link: LinkFieldType
    }
  }
  aboutSection: {
    title: string
    description1: string
    description2: string
    image: MediaFieldType
    media: MediaFieldType | (MuxVideoType & { playbackId: string })
  }
  aboutOxygenSection: {
    title: string
    description1: string
    description2: string
    image: MediaFieldType
    media: MediaFieldType | (MuxVideoType & { playbackId: string })
  }
  migrationSection: {
    title: string
    reasons: Array<{
      term: string
      definition: string
      media: MediaFieldType | (MuxVideoType & { playbackId: string })
    }>
  }
  aboutUsSection: {
    title: string
    introduction1: string
    introduction2: string
    caseStudies: Array<{
      title: string
      backgroundImage: MediaFieldType
      previewImage: MediaFieldType
      conversionRate: number
      salesRate: number
      path: string
      stats: Array<{ symbol: string; value: number; label: string }>
    }>
  }
  quoteSection: {
    quote: string
    author: string
    position: string
    avatar: MediaFieldType
  }
  helpSection: {
    title: string
    services: Array<{
      title: string
      description: string
      action: LinkFieldType
      actionLabel: string
    }>
  }
  resourcesSection: {
    title: string
    resources: Array<{
      title: string
      logo: MediaFieldType
      previewImage: MediaFieldType
      actions: Array<{
        label: string
        link: LinkFieldType
      }>
    }>
  }
  faqSection: {
    title: string
    faqs: Array<{
      question: string
      answer: PortableTextBlocks
    }>
    action: LinkFieldType
    actionLabel: string
  }
  seo?: Seo
}

async function getHydrogenPage({
  locale,
  preview = false,
}: {
  locale: string
  preview?: boolean
}): Promise<HydrogenPageResult> {
  const [, localeId] = getLocaleRegionIdFromPath(locale)
  const RICH_TEXT_PROJECTION = createRichTextProjection(locale)
  const SEO_PROJECTION = createSEOProjection(localeId)

  const query = groq`
      *[_type == 'hydrogen'] {
        heroSection {
            ${coalesceLocaleField('title', localeId)},
            ${coalesceLocaleField('description', localeId)},
            action {
              ${coalesceLocaleField('label', localeId)},
              link ${createLinkProjection(localeId)}
            },
            image ${createMediaProjection(localeId)}
        },
        aboutSection {
            ${coalesceLocaleField('title', localeId)},
            ${coalesceLocaleField('description1', localeId)},
            ${coalesceLocaleField('description2', localeId)},
            media[] {
              _type == 'media' => ${createMediaProjection(localeId)},
              _type == 'mux.video' => {
                ...asset -> {
                  "playbackId": data.playback_ids[0].id,
                  "firstFrame": "https://image.mux.com/" + data.playback_ids[0].id + "/thumbnail.jpg?time=0",
                  ...(${SELECTED_STATIC_RENDITION()}{width, height}),
                }
              }
            }[0],
        },
        aboutOxygenSection {
            ${coalesceLocaleField('title', localeId)},
            ${coalesceLocaleField('description1', localeId)},
            ${coalesceLocaleField('description2', localeId)},
            media[] {
              _type == 'media' => ${createMediaProjection(localeId)},
              _type == 'mux.video' => {
                ...asset -> {
                  "playbackId": data.playback_ids[0].id,
                  "firstFrame": "https://image.mux.com/" + data.playback_ids[0].id + "/thumbnail.jpg?time=0",
                  ...(${SELECTED_STATIC_RENDITION()}{width, height}),
                }
              }
            }[0],
        },
        migrationSection {
            ${coalesceLocaleField('title', localeId)},
            reasons[] {
                ${coalesceLocaleField('term', localeId)},
                ${coalesceLocaleField('definition', localeId)},
                media[] {
                  _type == 'media' => ${createMediaProjection(localeId)},
                  _type == 'mux.video' => {
                    ...asset -> {
                      "playbackId": data.playback_ids[0].id,
                      "firstFrame": "https://image.mux.com/" + data.playback_ids[0].id + "/thumbnail.jpg?time=0",
                      ...(${SELECTED_STATIC_RENDITION()}{width, height}),
                    }
                  }
                }[0],
            }
        },
        aboutUsSection {
            ${coalesceLocaleField('title', localeId)},
            ${coalesceLocaleField('introduction1', localeId)},
            ${coalesceLocaleField('introduction2', localeId)},
            caseStudies[] {
                ${coalesceLocaleField('title', localeId)},
                backgroundImage ${createMediaProjection(localeId)},
                previewImage ${createMediaProjection(localeId)},
                conversionRate,
                salesRate,
                "path": caseStudy->path.current,
                stats[] {
                    ${coalesceLocaleField('label', localeId)},
                    value,
                    symbol
                }
            }
        },
        quoteSection {
            ...,
            avatar ${createMediaProjection(localeId)}
        },
        helpSection {
            ${coalesceLocaleField('title', localeId)},
            services[] {
                ${coalesceLocaleField('title', localeId)},
                ${coalesceLocaleField('description', localeId)},
                action ${createLinkProjection(localeId)},
                ${coalesceLocaleField('actionLabel', localeId)}
            }
        },
        resourcesSection {
            ${coalesceLocaleField('title', localeId)},
            resources[] {
                ${coalesceLocaleField('title', localeId)},
                logo ${createMediaProjection(localeId)},
                previewImage ${createMediaProjection(localeId)},
                actions [] {
                    ${coalesceLocaleField('label', localeId)},
                    link ${createLinkProjection(localeId)}
                }
            }
        },
        faqSection {
            ${coalesceLocaleField('title', localeId)},
            faqs[] {
                ${coalesceLocaleField('question', localeId)},
                ${coalesceLocaleField(
                  'answer',
                  localeId,
                )}[]${RICH_TEXT_PROJECTION}
            },
            action ${createLinkProjection(localeId)},
            ${coalesceLocaleField('actionLabel', localeId)}
        },
        seo${SEO_PROJECTION}
      }[0]
    `

  const document = getClient(preview).fetch(query)

  return document
}

export { getHydrogenPage }
