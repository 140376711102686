import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@portfolio/configs'
import { createBaseImageProjection } from '../projections/createBaseImageProjection'
import { createMediaProjection } from '../projections/createMediaProjection'

export const createSectionVideoTestimonialsProjection = (
  localeId: LocaleId,
) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId, 'MEDIUM')

  return groq`{
        _key,
        _type,
        titleAppearance,
        spacing,
        ${coalesceLocaleField('title', localeId)},
        slides[]{
          ${coalesceLocaleField('personName', localeId)},
          ${coalesceLocaleField('personPosition', localeId)},
          logo${createBaseImageProjection(localeId)},
          media${MEDIA_PROJECTION}
        }
      }
  `
}
