import { styled } from '@portfolio/styles'
import React, { FC, useState } from 'react'
import { captureException } from '@sentry/nextjs'
import { useTranslation } from 'react-i18next'
import { Button } from '../Button/Button'
import { Check } from '@portfolio/icons'
import { GtmCopyMail } from '@portfolio/services/gtm-service'

type CopyButtonProps = {
  copyText: string
  theme?: 'dark' | 'light'
  context: string
}
const StyledButton = styled(Button, {
  display: 'inline-grid',
  projectFont: 'ui03',
  borderRadius: '$rMax',
  padding: '0 $4 ',
  textTransform: 'uppercase',
  placeItems: 'center',
  '@lg': {
    '&:hover': {
      opacity: 0.7,
    },
  },
  '&:active': {
    opacity: 0.7,
  },
  '&:focus-visible': {
    outline: '$focusBlue solid 3px;',
  },
  color: '$white_085',
})

const Info = styled('div', {
  projectFont: 'body04',
  padding: '$12 $24',
  display: 'flex',
  zIndex: '$content',
  alignItems: 'center',
  gap: '$12',
  whiteSpace: 'nowrap',
  borderRadius: '$r2',
  position: 'fixed',
  bottom: '10vh',
  left: '50%',
  transform: 'translateX(-50%)',
  opacity: 0,
  visibility: 'hidden',
  transition: 'opacity 200ms',
  backdropFilter: 'blur(10px)',
  variants: {
    isVisible: {
      true: {
        opacity: 1,
        visibility: 'visible',
        transition: 'opacity 200ms',
      },
    },
    theme: {
      light: {
        background: '$white_05',
      },

      dark: {
        background: '$black_08',
        color: '$white',
      },
    },
  },
})

export const CopyButton: FC<CopyButtonProps> = ({
  copyText,
  theme = 'light',
  context,
}) => {
  const { t } = useTranslation('contact')
  const [isCopied, setIsCopied] = useState(false)
  const INFO_TIMEOUT = 3000
  async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand('copy', true, text)
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true)
        setTimeout(() => {
          setIsCopied(false)
        }, INFO_TIMEOUT)
        GtmCopyMail(copyText, context)
      })
      .catch((err) => {
        captureException(err)
      })
  }

  return (
    <>
      <StyledButton onClick={() => handleCopyClick()} aria-label="Copy text">
        {/*{t('copy')}*/}
        <svg
          fill="none"
          shapeRendering="geometricPrecision"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
          viewBox="0 0 24 24"
          width="20"
          height="20"
        >
          <path d="M6 17C4.89543 17 4 16.1046 4 15V5C4 3.89543 4.89543 3 6 3H13C13.7403 3 14.3866 3.4022 14.7324 4M11 21H18C19.1046 21 20 20.1046 20 19V9C20 7.89543 19.1046 7 18 7H11C9.89543 7 9 7.89543 9 9V19C9 20.1046 9.89543 21 11 21Z" />
        </svg>
      </StyledButton>
      <Info isVisible={isCopied} theme={theme}>
        <Check />
        {t('copied')}
      </Info>
    </>
  )
}
