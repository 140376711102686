import { forwardRef, PropsWithChildren } from 'react'
import { styled } from '@portfolio/styles'

const Wrap = styled('section', {
  variants: {
    type: {
      DEFAULT: {
        marginTop: '$mobileSectionMarginL',
        marginBottom: '$mobileSectionMarginL',
        '@lg': {
          marginTop: '$desktopSectionMarginL',
          marginBottom: '$desktopSectionMarginL',
        },
      },
      CARD: {
        marginTop: '$mobileSectionMarginL',
        marginBottom: '$mobileSectionMarginL',
        '@lg': {
          marginTop: '$sectionMarginS',
          marginBottom: '$sectionMarginS',
          '&:last-of-type': {
            marginBottom: 0,
            borderBottom: 0,
          },
        },
      },
      MEDIA: {
        marginTop: '$sectionMarginM',
        marginBottom: '$sectionMarginM',
      },
      FIRST_SECTION: {
        marginTop: 'calc($headerHeight + $mobileSectionMarginL)',
        '@lg': {
          marginTop: 'calc($headerHeight + $desktopSectionMarginL)',
        },
      },
      NO_MARGIN: {},
      RICH_TEXT_SM: {
        marginTop: '$40',
        marginBottom: '$40',
        '&:first-child': {
          marginTop: 0,
        },
      },
      RICH_TEXT_MD: {
        marginTop: '$40',
        marginBottom: '$40',
        '@lg': {
          marginTop: '$72',
          marginBottom: '$72',
        },

        '&:first-child': {
          marginTop: 0,
        },
      },
      RICH_TEXT_LG: {
        marginTop: '$48',
        marginBottom: '$48',
        '@lg': {
          marginTop: '$96',
          marginBottom: '$96',
        },

        '&:first-child': {
          marginTop: 0,
        },
      },
    },
  },
})

export const SectionWrapper = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{
    type?:
      | 'DEFAULT'
      | 'CARD'
      | 'MEDIA'
      | 'RICH_TEXT_SM'
      | 'RICH_TEXT_MD'
      | 'RICH_TEXT_LG'
      | 'FIRST_SECTION'
      | 'NO_MARGIN'
    id?: string
    className?: string
  }>
>(({ children, className, type = 'DEFAULT', id }, ref) => {
  return (
    <Wrap className={className} id={id} ref={ref} type={type}>
      {children}
    </Wrap>
  )
})
