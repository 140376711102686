import { createBaseImageProjection } from './../projections/createBaseImageProjection'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { DEFAULT_LOCALE_ID, LocaleId } from '@portfolio/configs'

export const createSectionIconWithTextListProjection = (localeId: LocaleId) => {
  return groq`{
        _key,
        _type,
        type,
        ...select(
          type=='default'=>{
            items[]{
              ${coalesceLocaleField('title', localeId)},
              icon${createBaseImageProjection(localeId)},
              ${coalesceLocaleField('text', localeId)},
            }
          },
          type=='techFeatures'=>{
            "items":caseStudy->{
              techStack[]{
                "title":type,
                "text":coalesce(shortDescription.${localeId}, shortDescription.${DEFAULT_LOCALE_ID}),
                "icon":logo${createBaseImageProjection(localeId)},
              }
            }.techStack
          }
        )
        }`
}
