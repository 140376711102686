export const ArrowPrev = () => {
  return (
    <svg
      width="15"
      height="22"
      viewBox="0 0 15 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7071 21.7071C11.3166 22.0976 10.6834 22.0976 10.2929 21.7071L0.0524616 11.4667L10.2929 1.22624C10.6834 0.835717 11.3166 0.835717 11.7071 1.22624C12.0976 1.61677 12.0976 2.24993 11.7071 2.64046L2.88089 11.4667L11.7071 20.2929C12.0976 20.6834 12.0976 21.3166 11.7071 21.7071Z"
        fill="white"
      />
    </svg>
  )
}
