import { coalesceLocaleField } from './../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { createMediaProjection } from './createMediaProjection'
import { createSEOProjection } from './createSEOProjection'

export const createArticlesLandingPageProjection = (localeId: string) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const SEO_PROJECTION = createSEOProjection(localeId)
  return groq`{
      "type":_type,
      "showFooter": true,
      "headerStyle":"transparent-dark",
      "footerMobileButtonVisible":false,
      "seo": seo${SEO_PROJECTION},
      "pageBuilder": [{
         "_type": "sectionArticlesLandingPage",
         "_key": "sectionArticlesLandingPage" + _id,
         ${coalesceLocaleField('title', localeId)},
         articles[]->{
          image${MEDIA_PROJECTION},
          ${coalesceLocaleField('title', localeId)},
          "path":path.current,
         }
      }]
  }`
}
