import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@portfolio/configs'
import { createBaseImageProjection } from '../projections/createBaseImageProjection'

export const createSectionContactProjection = (localeId: LocaleId) => {
  const socialsQuery = groq`
    *[_type == "socials"]{
      items[]{
        url,
        logoLight${createBaseImageProjection(localeId)},
        logoDark${createBaseImageProjection(localeId)},
      }
    }[0]
  `

  return groq`{
        _key,
        _type,
        "socials":${socialsQuery},
        ${coalesceLocaleField('title', localeId)},
        contacts {
          ${coalesceLocaleField('title', localeId)},
          contact->{
              ${coalesceLocaleField('email', localeId)},
          }
        }[]
      }
  `
}
