import { styled } from '@portfolio/styles'
import { Close, Error } from '@portfolio/icons'
import React, { ChangeEvent, FormEvent, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { Socials as SocialsType } from '@portfolio/models'
import { CopyButton, Modal, Socials } from '../../index'
import { StyledClickable } from '../StyledClickable/StyledClickable'
import { GTMContactFormSubmit } from '@portfolio/services/gtm-service'

const ModalWrap = styled('div', {
  display: 'flex',
  position: 'relative',
  height: '100%',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '$40 $40 0',
  maxWidth: '640px',

  '@lg': {
    width: '640px',
  },
})
const ContentWrap = styled('div', {
  transition: 'all .5s cubic-bezier(0.5, 0, 0.1, 1)',

  variants: {
    isShow: {
      true: {
        opacity: 1,
      },
      false: {
        opacity: 0,
      },
    },
  },
})
const FormWrap = styled('div', {})
const CloseButton = styled('button', {
  position: 'absolute',
  top: '$40',
  right: '$40',
  width: 36,
  height: 36,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '$rMax',
  transition: '0.2s background',

  '&:hover': {
    background: '$gray500_02',
  },

  '&:active': {
    background: '$gray500_01',
  },

  '&:focus-visible': {
    outline: '$focusBlue solid 3px;',
  },
})
const ModalTitle = styled('h1', {
  projectFont: 'display02',
  marginBottom: '80px',
  paddingRight: '$32',
  '@sm': {
    paddingRight: '$48',
  },
  '@md': {
    paddingRight: '80px',
  },
})
const InputsWrap = styled('div', {
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr',
  alignItems: 'center',

  '@sm': {
    gap: '$20',
    gridTemplateColumns: '1fr 1fr',
  },
})

const InputWrap = styled('div', {})

const TextInput = styled('input', {
  width: '100%',
  height: '100%',
  borderRadius: '$r2',
  projectFont: 'body04',
  border: '1px solid $black_02',
  color: '$black',
  transition: 'color 250ms',
  padding: '$12 $20',
  textAlign: 'start',

  '&::placeholder': {
    color: '$black_06',
  },

  '&:focus-visible': {
    outline: '$focusBlue solid 3px;',
  },

  variants: {
    isError: {
      true: {
        borderColor: '$errorRed',
      },
    },
  },
})
const TextArea = styled('textarea', {
  width: '100%',
  borderRadius: '$r2',
  projectFont: 'body04',
  border: '1px solid $black_02',
  color: '$black',
  transition: 'color 250ms',
  padding: '$12 $20',
  textAlign: 'start',
  resize: 'none',
  minHeight: '$144',

  '&::placeholder': {
    color: '$black_06',
  },

  '&:focus-visible': {
    outline: '$focusBlue solid 3px;',
  },

  variants: {
    isError: {
      true: {
        borderColor: '$errorRed',
      },
    },
  },
})

{
  /* TODO: To be implemented in the future */
}
// const CheckboxWrap = styled('div', {
//   display: 'flex',
//   alignItems: 'center',
//   position: 'relative',
//   padding: '0 0 $32',
//   columnGap: '$8'
// })
//
// const CheckboxLabel = styled('label', {
//   projectFont: 'body04',
//   color: '$black_06',
// })
//
// const CheckboxContainer = styled('div', {
//   display: 'inline-block',
//   position: 'relative',
//   width: '$20',
//   height: '$20',
//   borderRadius: '4px',
//   border: '1px solid $black_02',
//   cursor: 'pointer',
//   '&:focus-within': {
//     outline: 'none',
//     borderColor: '$primary',
//   },
// });
//
// const CheckboxIcon = styled('div', {
//   position: 'absolute',
//   top: '0',
//   left: '0',
//   padding: '0',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   width: '100%',
//   height: '100%',
//   pointerEvents: 'none',
//   '& svg': {
//     visibility: 'hidden',
//   },
//
// });
//
// const CheckboxInput = styled('input', {
//   appearance: 'none',
//   opacity: 0,
//   width: '100%',
//   height: '100%',
//   margin: 0,
//   '&:checked + div': {
//     background: '$black',
//     padding: '0 $4',
//   },
//   '&:checked + div > svg': {
//     visibility: 'visible',
//     color: '$white', // Adjust the color to match your design
//   },
//   '&:focus': {
//     outline: 'none',
//   },
//   variants: {
//     isError: {
//       true: {
//         '+ div': {
//           borderColor: '#f00',
//         },
//       },
//     },
//   },
// });

const SubmitButton = styled(StyledClickable, {
  margin: '$12 0 $32',

  '&:focus-visible': {
    outline: '$focusBlue solid 3px;',
  },
})

const FormError = styled('div', {
  projectFont: 'ui04',
  color: '$errorRed',
  display: 'flex',
  flexDirection: 'row',
  columnGap: '$4',
  alignItems: 'center',
  justifyContent: 'flex-start',
  visibility: 'hidden',
  minHeight: '$24',

  variants: {
    isVisible: {
      true: {
        visibility: 'visible',
      },
    },
  },
})

{
  /* TODO: To be implemented in the future */
}
// const PrivacyText = styled('p', {
//   projectFont: 'ui05', // this style not match with figma
//   fontSize: '11px',
//   lineHeight: '200%',
//   margin: '$24 0 $32',
//   color: '$black_06',
// })

const ContactWrap = styled('div', {
  '@md': {
    margin: '0 auto',
  },
})

const Contact = styled('div', {
  padding: '$28 0 $32',
  borderTop: '1px solid $gray500_01',
})

const ContactTitle = styled('h2', {
  projectFont: 'heading08',
  marginBottom: '$8',
})

const ContactMail = styled('div', {
  projectFont: 'body03',
  display: 'flex',
  justifyContent: 'flex-start',
  columnGap: '$12',
})

const SocialsWrap = styled('div', {
  padding: '$28 0 $32',
})

const SocialsTitle = styled('h2', {
  projectFont: 'heading08',
  marginBottom: '$16',
})

const ResponseMessage = styled('div', {
  projectFont: 'heading08',
  marginBottom: '$32',
})

const ErrorMessage = ({ message }: { message: string }) => (
  <FormError isVisible={!!message}>
    {!!message && (
      <>
        <Error />
        {message}
      </>
    )}
  </FormError>
)

export type ContactFormModalProps = {
  socials: SocialsType
  contactForm?: {
    formTitle?: string
    formSuccessTitle?: string
    contactEmail?: {
      email?: string
    }
    emailTitle?: string
    formSuccessText?: string
  }
  renderTrigger?: (action: { click: () => void }) => ReactElement
}

export const ContactFormModal = ({
  socials,
  contactForm,
  renderTrigger,
}: ContactFormModalProps) => {
  const { t } = useTranslation('contactPage')
  const [isContactFormOpen, setIsContactFormOpen] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isShow, setIsShow] = useState(true)
  const [title, setTitle] = useState(contactForm?.formTitle)
  const [formState, setFormState] = useState({
    email: '',
    fullName: '',
    message: '',
    // TODO: To be implemented in the future
    // privacy: false,
  })

  const [formErrors, setFormErrors] = useState({
    email: '',
    fullName: '',
    message: '',
    // TODO: To be implemented in the future
    // privacy: '',
  })

  const validateField = (name: string, value: string | null | undefined) => {
    let error = ''

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    switch (name) {
      case 'email':
        if (!value || !emailRegex.test(value)) {
          error = 'Invalid email format'
        }
        break
      case 'fullName':
        if (!value || !value.trim()) {
          error = 'Full name is required'
        }
        break
      case 'message':
        if (!value || !value.trim()) {
          error = 'Message is required'
        }
        break
      default:
        break
    }
    return error
  }

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target
    const error = validateField(name, value)

    setFormErrors((prevErrors) => ({ ...prevErrors, [name]: error }))
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const formData = new FormData(e.target as HTMLFormElement)
    const email = formData.get('email')?.toString()

    const emailError = validateField('email', email)
    const fullNameError = validateField(
      'fullName',
      formData.get('fullName')?.toString(),
    )
    const messageError = validateField(
      'message',
      formData.get('message')?.toString(),
    )
    const privacyError = validateField('privacy', '')

    if (emailError || fullNameError || messageError || privacyError) {
      setFormErrors({
        email: emailError,
        fullName: fullNameError,
        message: messageError,
      })
      return
    }
    setIsShow(false)

    setTimeout(() => {
      GTMContactFormSubmit(email, 'Contact Modal')
      setIsSubmitted(true)
      setTitle(contactForm?.formSuccessTitle)
      setIsShow(true)
    }, 500)
  }

  const handleModalClose = () => {
    setIsContactFormOpen(false)
    setTimeout(() => {
      setIsSubmitted(false)
      setTitle(contactForm?.formTitle)
    }, 500)
  }

  const actionProps = {
    click: () => setIsContactFormOpen(true),
  }

  if (!renderTrigger) return null

  return (
    <>
      {renderTrigger(actionProps)}
      <Modal
        preventScroll={true}
        isOpen={isContactFormOpen}
        onRequestClose={handleModalClose}
        size={'stretch-y'}
        position={'right'}
        transition={'slide-from-right'}
      >
        <ModalWrap>
          <CloseButton onClick={handleModalClose}>
            <Close />
          </CloseButton>
          <ContentWrap isShow={isShow}>
            <ModalTitle>{title}</ModalTitle>
            {isSubmitted ? (
              <ResponseMessage>
                <p>{contactForm?.formSuccessText}</p>
              </ResponseMessage>
            ) : (
              <FormWrap>
                <form noValidate onSubmit={handleSubmit}>
                  <InputsWrap>
                    <InputWrap>
                      <TextInput
                        placeholder={'E-mail'}
                        type="email"
                        name="email"
                        value={formState.email}
                        onChange={handleInputChange}
                        isError={!!formErrors.email}
                      />
                      <ErrorMessage message={formErrors.email} />
                    </InputWrap>
                    <InputWrap>
                      <TextInput
                        type="text"
                        placeholder="Full name"
                        name="fullName"
                        value={formState.fullName}
                        onChange={handleInputChange}
                        isError={!!formErrors.fullName}
                      />
                      <ErrorMessage message={formErrors.fullName} />
                    </InputWrap>
                  </InputsWrap>
                  <InputWrap>
                    <TextArea
                      placeholder="Tell us a bit more how we can help"
                      name="message"
                      value={formState.message}
                      onChange={handleInputChange}
                      isError={!!formErrors.message}
                    />
                    <ErrorMessage message={formErrors.message} />
                  </InputWrap>
                  {/* TODO: To be implemented in the future */}
                  {/*<CheckboxWrap>*/}
                  {/*  <CheckboxContainer>*/}
                  {/*    <CheckboxInput*/}
                  {/*      type="checkbox"*/}
                  {/*      name="privacy"*/}
                  {/*      title="I'd like to get monthly digital news and insights (optional)"*/}
                  {/*      checked={formState.privacy}*/}
                  {/*      onChange={handleInputChange}*/}
                  {/*      isError={!!formErrors.privacy}*/}
                  {/*    />*/}
                  {/*    <CheckboxIcon>*/}
                  {/*      <Check />*/}
                  {/*    </CheckboxIcon>*/}
                  {/*  </CheckboxContainer>*/}
                  {/*  <CheckboxLabel>I'd like to get monthly digital news and insights (optional)</CheckboxLabel>*/}
                  {/*</CheckboxWrap>*/}

                  <SubmitButton
                    appearance="containedBlack"
                    size="medium"
                    type="submit"
                  >
                    Send request
                  </SubmitButton>
                </form>
                {/* TODO: To be implemented in the future */}
                {/*<PrivacyText>By clicking send request you accept our privacy policy.</PrivacyText>*/}
                {/*<ErrorMessage message={formErrors.privacy}/>*/}
              </FormWrap>
            )}
            <ContactWrap>
              <Contact>
                <ContactTitle>{contactForm?.emailTitle}</ContactTitle>
                <ContactMail>
                  {contactForm?.contactEmail?.email}
                  <CopyButton
                    copyText={contactForm?.contactEmail?.email ?? ''}
                    theme="dark"
                    context={`ContactPage - Prefer email contact?`}
                  />
                </ContactMail>
              </Contact>
            </ContactWrap>
          </ContentWrap>
          {socials && socials && (
            <SocialsWrap>
              <SocialsTitle>{t('followUs')}</SocialsTitle>
              <Socials
                items={socials.items}
                iconsColor="dark"
                priority={false}
              />
            </SocialsWrap>
          )}
        </ModalWrap>
      </Modal>
    </>
  )
}
