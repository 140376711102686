import { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { DefaultSeo } from 'next-seo'
import { PageBuilderSection, PageResponse } from '@portfolio/models'
import { seoImageLoader } from '@portfolio/components'
import { FC, PropsWithChildren } from 'react'
import Script from 'next/script'
import '../translations/i18n'

import { Header } from '@portfolio/header'
import { Footer } from '@portfolio/footer'
import { ArticleProvider } from '@portfolio/article'
import localFont from '@next/font/local'

type ProjectAppProps = AppProps<PageResponse<PageBuilderSection[]>>

const SuisseIntlRegular = localFont({
  src: '../public/fonts/SuisseIntl-Regular-WebS.woff2',
  weight: '400',
  variable: '--font-suisse-intl-regular',
})

const SuisseIntlBook = localFont({
  src: '../public/fonts/SuisseIntl-Book-WebS.woff2',
  weight: '400',
  variable: '--font-suisse-intl-book',
})

const SuisseIntlMono = localFont({
  src: '../public/fonts/SuisseIntlMono-Regular-WebS.woff2',
  weight: '400',
  variable: '--font-suisse-intl-mono',
})

const SuisseWorksRegular = localFont({
  src: '../public/fonts/SuisseWorks-Regular-WebS.woff2',
  weight: '400',
  variable: '--font-suisse-works-regular',
})

const SuisseWorksMedium = localFont({
  src: '../public/fonts/SuisseWorks-Medium-WebS.woff2',
  weight: '400',
  variable: '--font-suisse-works-medium',
})

const Layout: FC<PropsWithChildren<PageResponse<PageBuilderSection[]>>> = ({
  siteConfiguration,
  headerStyle,
  headerPosition,
  footerMobileButtonVisible,
  theme,
  showFooter,
  children,
  nextProject,
}) => {
  return (
    <>
      <style jsx global>{`
        :root {
          --font-suisse-intl-regular: ${SuisseIntlRegular.style.fontFamily};
          --font-suisse-intl-book: ${SuisseIntlBook.style.fontFamily};
          --font-suisse-intl-mono: ${SuisseIntlMono.style.fontFamily};
          --font-suisse-works-regular: ${SuisseWorksRegular.style.fontFamily};
          --font-suisse-works-medium: ${SuisseWorksMedium.style.fontFamily};
        }
      `}</style>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="preconnect" href="https://cdn.sanity.io"></link>
        <link rel="preconnect" href="https://image.mux.com"></link>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      {siteConfiguration?.seo &&
        (() => {
          const { seo } = siteConfiguration
          const seoImage = seoImageLoader(seo.ogImage, seo.ogAltImage)
          return (
            <DefaultSeo
              titleTemplate={seo.titleTemplate}
              title={seo.metaTitle}
              description={seo.metaDescription}
              openGraph={{
                title: seo.ogTitle,
                description: seo.ogDescription,
                images: [...(seoImage ? [seoImage] : [])],
              }}
              twitter={{
                cardType: 'summary_large_image',
              }}
            />
          )
        })()}
      {siteConfiguration?.header && (
        <Header
          header={siteConfiguration?.header}
          theme={headerStyle}
          position={headerPosition}
        />
      )}
      {children}
      {siteConfiguration?.footer && showFooter && (
        <Footer
          footer={siteConfiguration?.footer}
          footerMobileButtonVisible={footerMobileButtonVisible}
          theme={theme}
          nextProject={nextProject}
        />
      )}
    </>
  )
}
const MyApp = ({ Component, pageProps }: ProjectAppProps) => {
  const { locale } = useRouter()
  if (!locale) throw new Error('Locale has not been set')

  return (
    <>
      <style jsx global>
        {`
          :root {
            --main-bg-color: ${pageProps.theme === 'dark' ? '#121212' : '#fff'};
          }
        `}
      </style>
      <ArticleProvider>
        <Layout {...pageProps}>
          <Component {...pageProps} />
        </Layout>
        <div id={'modalContainer'} />
      </ArticleProvider>
      <Script
        strategy="afterInteractive"
        id="gtm"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer',"${process.env.NEXT_PUBLIC_GTM_ID}");

          `,
        }}
      />
    </>
  )
}
export default MyApp
