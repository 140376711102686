import React, { FC, PropsWithChildren } from 'react'
import NextLink, { LinkProps } from 'next/link'
import {
  StyledClickable,
  StyledClickableProps,
} from '../StyledClickable/StyledClickable'
import { LinkAction } from './LinkAction'
import { Action } from '@portfolio/models'

type ProjectLinkProps = PropsWithChildren<{
  action?: Action
  href?: LinkProps['href']
  shallow?: boolean
  label?: string
}> &
  StyledClickableProps & {
    target?: '_blank'
    className?: string
    download?: boolean
    title?: string
  }

export const Link: FC<ProjectLinkProps> = ({
  action,
  children,
  download,
  size,
  appearance = 'text',
  href,
  tabIndex,
  target,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  className,
  label,
  title,
}) => {
  const elementProps = {
    download,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onFocus,
    tabIndex,
    target,
  }

  const linkContent = (
    <StyledClickable
      as={'a'}
      className={className}
      appearance={appearance}
      size={size}
      title={title}
      aria-label={label}
      {...elementProps}
    >
      {children}
    </StyledClickable>
  )

  if (href) {
    return (
      <NextLink href={href} passHref title={title} legacyBehavior>
        {linkContent}
      </NextLink>
    )
  }

  if (action) {
    return (
      <LinkAction
        action={action}
        className={className}
        renderTrigger={(clickAction) => (
          <StyledClickable
            as={'button'}
            className={className}
            appearance={appearance}
            size={size}
            title={title}
            aria-label={label}
            {...elementProps}
            onClick={clickAction.click}
          >
            {children}
          </StyledClickable>
        )}
      />
    )
  }

  return null
}
