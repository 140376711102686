import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@portfolio/configs'
import { createEditorialRichTextProjection } from '../projections/createEditorialRichTextProjection'

export const createSectionArticleChapterProjection = (localeId: LocaleId) => {
  const EDITORIAL_RICH_TEXT_PROJECTION =
    createEditorialRichTextProjection(localeId)
  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('title', localeId)},
        ${coalesceLocaleField(
          'content',
          localeId,
        )}[]${EDITORIAL_RICH_TEXT_PROJECTION},      }
  `
}
