import { styled } from '@portfolio/styles'
import { FC, PropsWithChildren } from 'react'

const StyledPill = styled('div', {
  color: '$white',
  width: 'fit-content',
  borderRadius: '$r3',
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  variants: {
    appearance: {
      UI02: {
        projectFont: 'ui02',
        padding: '$0 $16',
        height: '$36',
        backgroundColor: '$white_005',
      },
      UI05: {
        projectFont: 'ui05',
        padding: '$0 $12',
        backgroundColor: '$white_005',
        height: '$32',
      },
      UI05Black: {
        projectFont: 'ui05',
        padding: '$0 $12',
        backgroundColor: '$white_005',
        color: '$black',
        border: '1px solid currentColor',
        height: '$32',
      },
      UI06Uppercase: {
        projectFont: 'ui06',
        textTransform: 'uppercase',
        padding: '$0 $12',
        backgroundColor: '$black_02',
        height: '$32',
      },
    },
    backdrop: {
      true: {
        backdropFilter: 'blur(30px)',
      },
    },
  },
})

const PillContent = styled('p', {
  display: 'block',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  width: 'min-content',
  maxWidth: '220px',
})

export const Pill: FC<
  PropsWithChildren<{
    as?: keyof HTMLElementTagNameMap
    appearance?: 'UI02' | 'UI05' | 'UI05Black' | 'UI06Uppercase'
    backdrop?: boolean
  }>
> = ({ children, appearance = 'UI02', backdrop = false, as }) => {
  if (!children) return null
  return (
    <StyledPill appearance={appearance} backdrop={backdrop}>
      <PillContent as={as}>{children}</PillContent>
    </StyledPill>
  )
}
