import React, { FC } from 'react'
import ReactModal from 'react-modal'
import { Overlay } from './Overlay'
import { Content } from './Content'

export const MODAL_CLASSES = {
  base: 'base',
  afterOpen: 'after-open',
  beforeClose: 'before-close',
}

export type ModalOverlayStyle = 'transparent' | 'blur' | 'light' | 'dark'

export type ModalContentPosition =
  | 'center'
  | 'bottom'
  | 'left'
  | 'right'
  | 'top'
export type ModalContentVariant = 'default' | 'white-rounded' | 'rounded'
export type ModalContentSize =
  | 'intrinsic'
  | 'stretch'
  | 'stretch-x'
  | 'stretch-y'

export type ModalTransition = 'fade' | 'slide-from-right'
export type ModalProps = ReactModal.Props & {
  // Content
  variant?: ModalContentVariant
  position?: ModalContentPosition
  size?: ModalContentSize
  // Overlay
  overlayCloseButton?: boolean
  overlayCloseButtonLabel?: string
  overlayVariant?: ModalOverlayStyle
  transition?: ModalTransition
  // Animations

  // General props - from 'react-modal'
  //
  // isOpen: boolean
  // onBeforeOpen: () => void
  // onAfterOpen: () => void
  // onBeforeClose: () => void
  // onAfterClose: () => void
  // preventScroll: boolean
  // onRequestClose: () => void
}

export const Modal: FC<ModalProps> = ({
  variant = 'default',
  position = 'center',
  size = 'intrinsic',
  overlayCloseButton,
  overlayCloseButtonLabel,
  overlayVariant = 'dark',
  transition = 'fade',
  isOpen,
  onRequestClose,
  children,
  ...reactModalProps
}) => {
  const transitionTime = 300

  const contentProps = { variant, position, size, transition }
  const overlayProps = {
    overlayCloseButton,
    overlayCloseButtonLabel,
    overlayVariant,
    transitionTime,
  }

  return (
    <ReactModal
      {...reactModalProps}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayElement={(props, contentElement) => (
        <Overlay
          {...props}
          {...overlayProps}
          hasPadding={position === 'center' && size === 'intrinsic'}
        >
          {contentElement}
        </Overlay>
      )}
      contentElement={(props, children) => (
        <Content {...props} {...contentProps}>
          {children}
        </Content>
      )}
      className={MODAL_CLASSES}
      overlayClassName={'Overlay'}
      closeTimeoutMS={transitionTime}
    >
      {children}
    </ReactModal>
  )
}
