import { createMediaProjection } from './../projections/createMediaProjection'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@portfolio/configs'

export const createSectionProjectCardGridProjection = (localeId: LocaleId) => {
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('title', localeId)},
        projects[] {
          ${coalesceLocaleField('text', localeId)},
          media${MEDIA_PROJECTION},
          caseStudy->{
            name,
            "path":path.current,
          }
        }
      }`
}
