export const GtmCopyMail = (mail: string, context: string) => {
  if (!mail) return
  const w = window as any
  w.dataLayer = w.dataLayer || []
  w.dataLayer.push({
    event: 'copy_mail',
    mail: mail,
    context: context,
  })
}
