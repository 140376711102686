import { createMediaProjection } from './createMediaProjection'
import { groq } from 'next-sanity'
import { createPageBuilderProjection } from './createPageBuilderProjection'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createBaseImageProjection } from './createBaseImageProjection'
import { createSEOProjection } from './createSEOProjection'
import { createEditorialRichTextProjection } from './createEditorialRichTextProjection'

export const createCaseStudyPageProjection = (localeId: string) => {
  const PAGE_BUILDER_PROJECTION = createPageBuilderProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const SEO_PROJECTION = createSEOProjection(localeId)
  const EDITORIAL_RICH_TEXT_PROJECTION =
    createEditorialRichTextProjection(localeId)

  return groq`{
    "type":_type,
    "showFooter": true,
    "headerStyle":"transparent-light",
    "footerMobileButtonVisible":false,
    "addBottomMargin":true,
    "seo": seo${SEO_PROJECTION},
    nextProject->{name,
      "path":path.current,
      image${MEDIA_PROJECTION},
    },
    "pageBuilder": [
      {
        "_type": "sectionCaseStudyHero",
        "_key": "sectionCaseStudyHero" + _id,
       image${MEDIA_PROJECTION},
       name,
       "services": servicesReferences[]->{
          ${coalesceLocaleField('title', localeId)},
        }.title,
       ${coalesceLocaleField('shortDescription', localeId)},
      },
      {
        "_type": "sectionCaseStudyDetails",
        "_key": "sectionCaseStudyDetails" + _id,
        url,
        design,
        ${coalesceLocaleField(
          'introduction',
          localeId,
        )}[]${EDITORIAL_RICH_TEXT_PROJECTION},
        techStack[] {
          name,
          type,
          url,
          ${coalesceLocaleField('shortDescription', localeId)},
          logo${createBaseImageProjection(localeId)}
        }
      },
      ...pageBuilder[]${PAGE_BUILDER_PROJECTION}
    ],
}`
}
