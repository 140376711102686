interface IconOpenNewTabProps {
  fill?: string
}

export const OpenNewTab = ({ fill = 'currentColor' }: IconOpenNewTabProps) => {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2197 1.57324H4.08393V0.573242H11.9268V8.26852H10.9268V2.28035L1.78035 11.4268L1.07324 10.7197L10.2197 1.57324Z"
        fill={fill}
      />
    </svg>
  )
}
