import { groq } from 'next-sanity'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { LocaleId } from '@portfolio/configs'

type VideoPreferQuality = 'HIGH' | 'MEDIUM'

export const sanityImageProjection = (fieldName: string) => groq`{
  ...${fieldName},
  ...${fieldName}.asset -> {
    "width": metadata.dimensions.width,
    "height": metadata.dimensions.height,
    "unoptimized": extension == "svg",
  }
}`

export const SELECTED_STATIC_RENDITION = (
  preferQuality: VideoPreferQuality = 'HIGH',
) => groq`
  select(
  ${
    preferQuality === 'HIGH'
      ? 'count(data.static_renditions.files[name=="high.mp4"]) > 0  => data.static_renditions.files[name=="high.mp4"][0],'
      : ''
  }
  count(data.static_renditions.files[name=="medium.mp4"]) > 0  => data.static_renditions.files[name=="medium.mp4"][0],
  count(data.static_renditions.files[name=="low.mp4"]) > 0  => data.static_renditions.files[name=="low.mp4"][0]
)
`

export const MUX_VIDEO_PROJECTION = (
  preferQuality: VideoPreferQuality = 'HIGH',
) => groq`{
  "static_renditions":data.static_renditions,
  ...(${SELECTED_STATIC_RENDITION(preferQuality)}{width, height}),
  "mp4": "https://stream.mux.com/" + data.playback_ids[0].id + "/" + ${SELECTED_STATIC_RENDITION(
    preferQuality,
  )}.name,
  "aspectRatio": data.aspect_ratio,
  "duration": data.duration,
  "firstFrame": "https://image.mux.com/" + data.playback_ids[0].id + "/thumbnail.webp?time=0",
  "id": data.id,
}`

export const createMediaProjection = (
  localeId: LocaleId,
  videoPreferQuality: VideoPreferQuality = 'HIGH',
) => groq`{
    ${coalesceLocaleField('alt', localeId)},
    mediaType == 'image' => {
      "mediaPayload": {
        "type": "sanity-image",
        "image": ${sanityImageProjection('image')},
        overrideMobileImage && defined(mobileImage) => {
          "mobileImage": ${sanityImageProjection('mobileImage')}
        }
      }
    },
    mediaType == 'video' => {
      "mediaPayload": {
        "type": "mux-video",
        "video": video.asset -> ${MUX_VIDEO_PROJECTION(videoPreferQuality)},
        "mobileVideo": mobileVideo.asset -> ${MUX_VIDEO_PROJECTION(
          videoPreferQuality,
        )}
      }
    },
   }`
