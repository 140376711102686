export const Pause = () => {
  return (
    <svg
      width="9"
      height="12"
      viewBox="0 0 9 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.18258 0.252136C0.815414 0.252136 0.518311 0.549564 0.518311 0.916402V11.0836C0.518311 11.3503 0.675295 11.5803 0.902339 11.6861C0.987967 11.7257 1.08268 11.7479 1.18258 11.7479H1.60942C1.97658 11.7479 2.27369 11.4504 2.27369 11.0836V0.916402C2.27369 0.549564 1.97658 0.252136 1.60942 0.252136H1.18258Z"
        fill="white"
      />
      <path
        d="M6.82884 0.560997C6.76397 0.663735 6.72634 0.78569 6.72634 0.916402V11.0836C6.72634 11.3154 6.8457 11.5195 7.02474 11.6384C7.12983 11.7076 7.25568 11.7479 7.39061 11.7479H7.81745C8.18462 11.7479 8.48172 11.4504 8.48172 11.0836V0.916402C8.48172 0.549564 8.18462 0.252136 7.81745 0.252136H7.39061C7.15448 0.252136 6.9469 0.375389 6.82884 0.560997Z"
        fill="white"
      />
    </svg>
  )
}
