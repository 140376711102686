import { FC } from 'react'
import { MediaFieldType } from '@portfolio/models'
import { styled } from '@portfolio/styles'
import { Container, Link, Media, StyledClickable } from '@portfolio/components'
import { useTranslation } from 'react-i18next'

const StyledLink = styled(Link, {
  minHeight: '65vh',
  backgroundColor: '$black',
  display: 'grid',
  color: '$white',
})
const Content = styled(Container, {
  gridColumn: 1,
  gridRow: 1,
  zIndex: '$content',
  display: 'grid',
  alignContent: 'space-between',
  padding: '$40 0',
})
const Title = styled('p', {
  projectFont: 'heading07',
})
const MediaWrap = styled('div', {
  gridColumn: 1,
  gridRow: 1,
})
export const NextProject: FC<{
  name?: string
  path?: string
  image?: MediaFieldType
}> = ({ name, path, image }) => {
  const { t } = useTranslation('caseStudy')

  return (
    <StyledLink href={path} appearance="grid" tabIndex={-1}>
      <MediaWrap>
        {image && (
          <Media
            {...image}
            layout="fill"
            hardcropRatio={{
              '420': 'portrait2',
              '768': 'portrait2',
              '1024': 'landscape1',
              '1400': 'landscape1',
              '1600': 'landscape1',
              '1920': 'landscape1',
              '2560': 'landscape1',
            }}
          />
        )}
      </MediaWrap>
      <Content>
        {name && <Title>{t('nextProject', { value: name })}</Title>}
        <div>
          <StyledClickable appearance="containedTransparent">
            {t('viewCaseStudy')}
          </StyledClickable>
        </div>
      </Content>
    </StyledLink>
  )
}
