export const GTMContactFormSubmit = (mail?: string, context?: string) => {
  if (!mail) return
  const w = window as any
  w.dataLayer = w.dataLayer || []
  w.dataLayer.push({
    event: 'contact_form_submit',
    ...(mail && { mail }),
    context: context ?? 'unknown',
  })
}
