import localesJson from './locales.json'

export type Locale = {
  id: string
  label: string
  shortLabel: string
  isDefault: boolean
}

export const DEFAULT_LOCALE_ID = 'en'

export const locales = localesJson as Locale[]

export const supportedLanguagesIds = locales.map((locale) => locale.id)

export const isSupportedLocale = (str: string): str is LocaleId => {
  return supportedLanguagesIds.includes(str as LocaleId)
}

export type LocaleId = typeof supportedLanguagesIds[number]

export const normalizedLocales = locales.reduce<Record<LocaleId, Locale>>(
  (acc, locale) => {
    return {
      ...acc,
      [locale.id]: locale,
    }
  },
  {} as Record<LocaleId, Locale>,
)
