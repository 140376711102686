import React, { ComponentPropsWithRef } from 'react'
import { styled } from '@portfolio/styles'
import { ModalOverlayStyle } from './Modal'
import { Button } from '../Button/Button'
import { useTranslation } from 'react-i18next'

const OverlayStyled = styled('div', {
  position: 'fixed',
  inset: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  gridGap: '$24',
  overflow: 'auto',
  zIndex: 9999,
  transition: 'transform .3s cubic-bezier(0.5, 0, 0.1, 1)',
  variants: {
    overlayVariant: {
      transparent: {},
      blur: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(32px)',
      },
      light: {},
      dark: {
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
      },
    },
    hasPadding: {
      true: {
        padding: '0',
      },
    },
  },
  opacity: 0,
  '&.ReactModal__Overlay--after-open': { opacity: 1 },
  '&.ReactModal__Overlay--before-close': { opacity: 0 },
})

const ButtonWrapper = styled('div', {
  margin: '0 auto',
})

export type OverlayProps = ComponentPropsWithRef<'div'> & {
  overlayCloseButton?: boolean
  overlayCloseButtonLabel?: string
  overlayVariant?: ModalOverlayStyle
  hasPadding?: boolean
  transitionTime?: number
}

export const Overlay = React.forwardRef<HTMLDivElement, OverlayProps>(
  (
    {
      overlayCloseButton,
      overlayCloseButtonLabel,
      transitionTime,
      children,
      hasPadding,
      ...restProps
    },
    ref,
  ) => {
    const { t } = useTranslation('modal')
    return (
      <OverlayStyled
        {...restProps}
        style={{}}
        css={{ transition: `opacity ${transitionTime}ms` }}
        ref={ref}
        hasPadding={hasPadding}
      >
        <>
          {children}
          {overlayCloseButton && (
            <ButtonWrapper>
              <Button size={'medium'}>
                {overlayCloseButtonLabel ?? t('close')}
              </Button>
            </ButtonWrapper>
          )}
        </>
      </OverlayStyled>
    )
  },
)
Overlay.displayName = 'Overlay'
