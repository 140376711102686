import { Pause, Play } from '@portfolio/icons'
import { keyframes, styled } from '@portfolio/styles'
import { FC, MouseEventHandler } from 'react'
import { VideoControlsPositionType } from '../models/MediaProps'

type VideoControlButtonProps = {
  isPlaying?: boolean
  isLoading?: boolean
  controlsPosition?: VideoControlsPositionType
  onRequestPause: MouseEventHandler<HTMLButtonElement>
  onRequestPlay: MouseEventHandler<HTMLButtonElement>
}

const ANIM_DURATION = 300

const ControlsContainer = styled('div', {
  display: 'grid',
  position: 'absolute',
  zIndex: '$videoControls',
  right: '$16',
  variants: {
    controlsPosition: {
      'top-right': {
        top: '$16',
        right: '$16',
      },
      'bottom-right': {
        bottom: '$16',
        right: '$16',
      },
      none: {
        display: 'none',
      },
    },
  },
})

const PlayIconWrap = styled('span', {
  position: 'absolute',
  opacity: 0,
  transitionProperty: 'opacity',
  display: 'flex',
})

const PauseIconWrap = styled('span', {
  position: 'absolute',
  transitionProperty: 'opacity',
  opacity: 1,
  display: 'flex',
})

const ControlButton = styled('button', {
  gridArea: '1/1',
  color: '$white',
  // This is display flex because of on Safari 13 <button/> GRID not working as expected
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  pointerEvents: 'all',
  width: '$36',
  height: '$36',
  background: '$gray500_02',
  backdropFilter: 'blur(10px)',
  opacity: 0,
  transitionProperty: 'opacity',
  borderRadius: '$rMax',
  '&:hover': {
    background: '$gray500_03',
    cursor: 'pointer',
  },
  '&:active': {
    background: '$gray500_04',
  },

  '&:focus-visible': {
    outline: '$focusBlue solid 3px;',
  },

  variants: {
    isLoading: {
      false: {
        opacity: 1,
        transitionDuration: `${ANIM_DURATION}ms`,
        transitionDelay: `${ANIM_DURATION / 4}ms`,
      },
      true: {
        opacity: 0,
        transitionDuration: `${ANIM_DURATION}ms`,
        transitionDelay: `${ANIM_DURATION * 2}ms`,
        pointerEvents: 'none',
      },
    },
    isPlaying: {
      true: {
        [`& ${PlayIconWrap}`]: {
          opacity: 0,
        },
        [`& ${PauseIconWrap}`]: {
          opacity: 1,
        },
      },
      false: {
        [`& ${PauseIconWrap}`]: {
          opacity: 0,
        },
        [`& ${PlayIconWrap}`]: {
          opacity: 1,
        },
      },
    },
  },
  compoundVariants: [
    {
      isLoading: true,
      isPlaying: false,
      css: {
        [`& ${PauseIconWrap}`]: {
          opacity: 1,
          transitionDuration: `${ANIM_DURATION}ms`,
          transitionDelay: `${ANIM_DURATION * 2}ms`,
        },
        [`& ${PlayIconWrap}`]: {
          opacity: 0,
          transitionDuration: `${ANIM_DURATION}ms`,
          transitionDelay: `${ANIM_DURATION * 2}ms`,
        },
      },
    },
  ],
})

const rotate = keyframes({
  '0%': { transform: 'rotate(0)' },
  '100%': { transform: 'rotate(360deg)' },
})

const Spinner = styled('span', {
  gridArea: '1/1',
  color: '$white',
  display: 'block',
  alignSelf: 'center',
  justifySelf: 'center',
  content: '',
  width: '$36',
  height: '$36',
  margin: 'auto',
  border: '2px solid $gray500_02',
  borderTopColor: 'currentColor',
  borderRadius: '50%',
  opacity: 0,
  transitionProperty: 'opacity',
  variants: {
    isLoading: {
      true: {
        opacity: 1,
        transitionDuration: `${ANIM_DURATION}ms`,
        transitionDelay: `${ANIM_DURATION * 2}ms`,
      },
      false: {
        opacity: 0,
        transitionDuration: `${ANIM_DURATION / 2}ms`,
        transitionDelay: `${ANIM_DURATION / 2}ms`,
      },
    },
  },
  animation: `${rotate} 1s linear infinite`,
})

export const VideoControlButton: FC<VideoControlButtonProps> = ({
  isPlaying,
  isLoading,
  onRequestPause,
  onRequestPlay,
  controlsPosition = 'bottom-right',
}) => {
  return (
    <ControlsContainer controlsPosition={controlsPosition}>
      <Spinner isLoading={isLoading} />
      <ControlButton
        isLoading={isLoading}
        isPlaying={isPlaying}
        onClick={isPlaying ? onRequestPause : onRequestPlay}
        aria-label={isPlaying ? 'pause' : 'play'}
      >
        <PauseIconWrap>
          <Pause />
        </PauseIconWrap>
        <PlayIconWrap>
          <Play />
        </PlayIconWrap>
      </ControlButton>
    </ControlsContainer>
  )
}
