import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@portfolio/configs'
import { createBaseImageProjection } from '../projections/createBaseImageProjection'

export const createSectionServicesProjection = (localeId: LocaleId) => {
  return groq`{
        _key,
        _type,

        ${coalesceLocaleField('text', localeId)},
        technologiesRows[] {
          items[]${createBaseImageProjection(localeId)},
        },

        services[] {
            _key,
            ${coalesceLocaleField('title', localeId)},
            ${coalesceLocaleField('description', localeId)},
            "pills": services[]->{
              _key,
              ${coalesceLocaleField('title', localeId)},
            }
        }
      }
  `
}
