import {
  useState,
  createContext,
  FC,
  useContext,
  PropsWithChildren,
} from 'react'

export type ArticleContextType = {
  visibleChapterId: string | null
  setVisibleChapterId: (id: string) => void
}

export const ArticleContext = createContext<ArticleContextType>({
  visibleChapterId: null,
  setVisibleChapterId: () => null,
})

export const ArticleProvider: FC<PropsWithChildren> = ({ children }) => {
  const [visibleChapterId, setVisibleChapterId] = useState<string | null>(null)

  return (
    <ArticleContext.Provider
      value={{
        visibleChapterId,
        setVisibleChapterId,
      }}
    >
      {children}
    </ArticleContext.Provider>
  )
}

export const useArticleContext = (): ArticleContextType => {
  return useContext(ArticleContext)
}
