import React, { ChangeEvent, FC, FormEvent, useState } from 'react'
import { MediaFieldType, SiteConfiguration, Theme } from '@portfolio/models'
import { keyframes, styled } from '@portfolio/styles'
import {
  CopyButton,
  Container,
  Link,
  Socials,
  PortableText,
  StyledClickable,
} from '@portfolio/components'
import { NextProject } from './components/NextProject'
import { Error, Approve } from '@portfolio/icons'
import { useTranslation } from 'react-i18next'

type Props = {
  footer: SiteConfiguration['footer']
  footerMobileButtonVisible: boolean
  theme: Theme
  nextProject?: {
    name?: string
    path?: string
    image?: MediaFieldType
  }
}

const Wrap = styled('div', {
  backgroundColor: '$darkThemeBackground',
  display: 'grid',
  color: '$white',
  paddingBottom: '$20',
  paddingTop: '$96',

  '@lg': {
    paddingBottom: '$24',
    paddingTop: '$144',
  },
})
const StyledContainer = styled(Container, {
  display: 'grid',
  gap: '$96',
  '@lg': {
    rowGap: '$144',
  },
})

const BottomWrap = styled('div', {
  rowGap: '$40',
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: 'auto auto',
  '@lg': {
    rowGap: '$40',
    justifyItems: 'center',
    gridTemplateColumns: 'repeat(3, 1fr)',
    alignItems: 'center',
  },
})
const JoinUsWrap = styled('div', {
  display: 'grid',
  alignItems: 'center',
  alignSelf: 'center',
  padding: '10px $20',
  borderRadius: '$rMax',
  border: '1px solid $white_02',
  gridColumn: 'span 2',
  gridTemplateColumns: 'auto 1fr auto',
  '@lg': {
    justifySelf: 'center',
    gridRow: 1,
    gridColumn: 2,
  },
})
const JoinUsText = styled('div', {
  projectFont: 'ui04',
  color: '$white_06',
  paddingLeft: '$16',
  paddingRight: '$32',
  '@lg': {
    projectFont: 'body04',
  },
})
const JoinUsButton = styled(Link, {
  display: 'flex',
  margin: '0 auto',
  color: '$white',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$8',
  projectFont: 'body04',

  zIndex: '$content',
  '&:focus-visible': {
    outline: '$focusBlue solid 3px;',
  },
})

const BottomItems = styled('div', {
  display: 'grid',
  gap: '$24',
  gridAutoFlow: 'column',
  projectFont: 'ui03',
  textTransform: 'uppercase',
  color: '$white_06',
  justifyContent: 'space-between',
  whiteSpace: 'nowrap',
  justifySelf: 'start',
  '@lg': {
    gridRow: 1,
    gridColumn: 1,
  },
})
const ContactInfoWrap = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr',
  justifyItems: 'center',
  textAlign: 'center',

  '@md': {
    gridTemplateColumns: 'repeat(2, 1fr)',
    justifyItems: 'unset',
    textAlign: 'left',
  },
})

const ContactWrap = styled('div', {
  maxWidth: 400,
  display: 'grid',
  gap: '$32',
  justifySelf: 'center',

  '@md': {
    justifySelf: 'start',
  },
})

const NewsletterWrap = styled('div', {
  display: 'grid',
  gap: '$8',
  justifySelf: 'center',
  maxWidth: '400px',
  paddingTop: '$32',

  '@md': {
    paddingTop: '0',
    justifySelf: 'end',
  },
})
const NewsletterTitle = styled('div', {
  projectFont: 'heading07',
})

const fadeOut = keyframes({
  '0%': { opacity: 1, transform: 'translateY(0)' },
  '100%': { opacity: 0, transform: 'translateY(-20px)' },
})

const fadeIn = keyframes({
  '0%': { opacity: 0, transform: 'translateY(20px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
})

const NewsletterForm = styled('div', {
  margin: '$24 0',
  display: 'grid',
  gridTemplateColumns: '2fr 1fr',
  gap: '$16',
  gridTemplateRows: '1fr',

  animation: `${fadeOut} 0.5s forwards`,
  variants: {
    isVisible: {
      true: {
        display: 'grid',
        animation: `${fadeIn} 0.5s forwards`,
      },
      false: {
        display: 'none',
      },
    },
  },
})
const NewsletterInputWrap = styled('div', {})
const NewsletterInput = styled('input', {
  width: '100%',
  borderRadius: '12px',
  projectFont: 'body04',
  border: '1px solid $white_06',
  color: '$white_06',
  transition: 'color 250ms',
  padding: '$12 $20',
  textAlign: 'start',
  background: '$darkThemeBackground',

  '&::placeholder': {
    color: '$white_06',
  },

  '&:focus-visible': {
    outline: '$focusBlue solid 3px;',
  },

  variants: {
    isError: {
      true: {
        borderColor: '$errorRed',
      },
    },
  },
})
const NewsletterInputError = styled('div', {
  projectFont: 'ui04',
  color: '$errorRed',
  display: 'flex',
  flexDirection: 'row',
  columnGap: '$4',
  alignItems: 'center',
  justifyContent: 'flex-start',
  minHeight: '$24',

  variants: {
    isVisible: {
      true: {
        display: 'flex',
      },
      false: {
        display: 'none',
      },
    },
  },
})

const NewsletterButton = styled(StyledClickable, {
  margin: '0',

  '&:focus-visible': {
    outline: '$focusBlue solid 3px;',
  },
})

const NewsletterSuccess = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '$24 0',
  gap: '$4',
  animation: `${fadeIn} 0.5s forwards`,

  '@md': {
    justifyContent: 'flex-start',
  },

  variants: {
    isVisible: {
      true: {
        display: 'flex',
      },
      false: {
        display: 'none',
      },
    },
  },
})

const NewsletterSuccessText = styled('div', {
  projectFont: 'body03',
})

const NewsletterPrivacyPolicyInfo = styled('div', {
  projectFont: 'ui04',
  color: '$white_06',
})

const Title = styled('div', {
  projectFont: 'display02',
  paddingBottom: '$32',
})
const Text = styled('div', {
  projectFont: 'body03',
})
const Contact = styled('div', {
  projectFont: 'body03',
  display: 'grid',
  gap: '$8',
  justifyContent: 'center',
  alignItems: 'center',
  gridTemplateColumns: 'auto auto',

  '@md': {
    justifyContent: 'flex-start',
  },
})

const pulse = keyframes({
  '0%': {
    transform: 'scale(0.95)',
    boxShadow: '0 0 0 0 rgba(27,198,81, 0.7)',
  },
  '70%': {
    transform: 'scale(1)',
    boxShadow: '0 0 0 8px rgba(27,198,81, 0)',
  },
  '100%': {
    transform: 'scale(0.95)',
    boxShadow: '0 0 0 0 rgba(27,198,81,0)',
  },
})
const NextProjectWrap = styled('div', {
  borderRadius: '$r2',
  overflow: 'hidden',
})

const PulseIcon = styled('div', {
  width: '$8',
  height: '$8',
  background: 'rgb(27,198,81)',
  borderRadius: '$rMax',
  boxShadow: '0 0 0 0 rgba(27,198,81, 1)',
  transform: 'scale(1)',
  animation: `${pulse} 1.6s infinite`,
})

const ErrorMessage = ({ message }: { message: string }) => {
  const isMessage = message !== ''
  return (
    <NewsletterInputError isVisible={isMessage}>
      {isMessage && (
        <>
          <Error />
          {message}
        </>
      )}
    </NewsletterInputError>
  )
}

export const Footer: FC<Props> = ({ footer, nextProject }) => {
  const { t } = useTranslation('footer')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [isSubscribed, setIsSubscribed] = useState(false)

  const validateField = (value: string) => {
    let error = ''
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(value)) {
      error = t('newsletterSubscribeErrorMessage')
    }
    return error
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setEmail(value)
  }
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const emailError = validateField(email)

    if (emailError) {
      setEmailError(emailError)
      return
    }

    setEmail('')
    setIsSubscribed(true)
  }

  return (
    <Wrap>
      <StyledContainer>
        <ContactInfoWrap>
          <ContactWrap>
            <div>
              <Title>{footer?.info?.title}</Title>
              <Text>{footer?.info?.text}</Text>
            </div>
            <Contact>
              {footer?.info?.contact?.email}
              {footer?.info?.contact?.email && (
                <CopyButton
                  copyText={footer?.info?.contact?.email}
                  context="Footer"
                />
              )}
            </Contact>
          </ContactWrap>
          <NewsletterWrap>
            <NewsletterTitle>{footer?.newsletter?.title}</NewsletterTitle>
            <form noValidate onSubmit={handleSubmit} id="footer-newsletter">
              <NewsletterForm isVisible={!isSubscribed}>
                <NewsletterInputWrap>
                  <NewsletterInput
                    type="email"
                    placeholder="E-mail"
                    name="e-mail"
                    value={email}
                    onChange={handleInputChange}
                    isError={emailError !== ''}
                  />
                  <ErrorMessage message={emailError} />
                </NewsletterInputWrap>
                <NewsletterButton
                  appearance="containedTransparent"
                  size="medium"
                  type="submit"
                >
                  {t('newsletterSubscribe')}
                </NewsletterButton>
              </NewsletterForm>
            </form>
            <NewsletterSuccess isVisible={isSubscribed}>
              <Approve color="green" size="medium" />
              <NewsletterSuccessText>
                {t('newsletterSuccessText')}
              </NewsletterSuccessText>
            </NewsletterSuccess>
            <NewsletterPrivacyPolicyInfo>
              <span>{t('newsletterPrivacyText')} </span>
              <Link appearance="textUnderline" href="/privacy-policy">
                {t('newsletterPrivacyPolicy')}
              </Link>
            </NewsletterPrivacyPolicyInfo>
          </NewsletterWrap>
        </ContactInfoWrap>
        {nextProject && (
          <NextProjectWrap>
            <NextProject {...nextProject} />
          </NextProjectWrap>
        )}

        <BottomWrap>
          {footer && footer.joinUs && (
            <JoinUsWrap>
              <PulseIcon />
              <JoinUsText>
                <PortableText
                  appearance="plain"
                  value={footer.joinUs.description}
                />
              </JoinUsText>
              <JoinUsButton
                {...footer.joinUs.ctaLink}
                target={
                  footer.joinUs.ctaLink?.linkType === 'external'
                    ? '_blank'
                    : undefined
                }
              >
                {footer.joinUs.ctaLabel}
              </JoinUsButton>
            </JoinUsWrap>
          )}
          <BottomItems>
            {footer?.bottomInfo &&
              footer?.bottomInfo.map((item, index) => {
                return item.itemType === 'text' ? (
                  <p key={`bottom-text-${index}`}>
                    &#169; {new Date().getFullYear()} {item?.text}
                  </p>
                ) : (
                  <Link
                    appearance="text"
                    {...item.ctaLink}
                    key={`bottom-link-${index}`}
                  >
                    {item.ctaLabel}
                  </Link>
                )
              })}
          </BottomItems>
          <Socials items={footer?.socials?.items} />
        </BottomWrap>
      </StyledContainer>
    </Wrap>
  )
}
