import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@portfolio/configs'

export const createSectionSocialMediaEmbedProjection = (localeId: LocaleId) => {
  return groq`{
      _key,
      _type,
      ${coalesceLocaleField('headline', localeId)},
      embeds[] {
        url,
        postUrl,
        width,
        embedType,
        desktopHeight,
        mobileHeight,
      }
    }
  `
}
