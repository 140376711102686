import { createSectionSingleMediaWithDescriptionProjection } from './../sections/createSectionSingleMediaWithDescriptionProjection'
import { createSectionIconWithTextListProjection } from './../sections/createSectionIconWithTextListProjection'
import { DEFAULT_LOCALE_ID, LocaleId } from '@portfolio/configs'
import { groq } from 'next-sanity'
import { createSectionQuoteProjection } from '../sections/createSectionQuoteProjection'
import { createRichTextProjection } from './createRichTextProjection'
import { createSectionVideoPlayerProjection } from '../sections/createSectionVideoPlayerProjection'
import { createSectionQuoteWithVideoProjection } from '../sections/createSectionQuoteWithVideoProjection'
import { createSectionStatsProjection } from '../sections/createSectionStatsProjection'

export const createEditorialRichTextProjection = (
  localeId: LocaleId,
  defaultLocaleId = DEFAULT_LOCALE_ID,
) => {
  const SECTION_QUOTE_PROJECTION = createSectionQuoteProjection(localeId)
  const SECTION_ICON_WITH_TEXT_LIST_PROJECTION =
    createSectionIconWithTextListProjection(localeId)
  const SECTION_SINGLE_MEDIA_WITH_DESCRIPTION =
    createSectionSingleMediaWithDescriptionProjection(localeId)
  const SECTION_VIDEO_PLAYER = createSectionVideoPlayerProjection(localeId)
  const SECTION_STATS_PROJECTION = createSectionStatsProjection(localeId)
  const RICH_TEXT_PROJECTION = createRichTextProjection(
    localeId,
    defaultLocaleId,
  )
  const SECTION_QUOTE_WITH_VIDEO_PROJECTION =
    createSectionQuoteWithVideoProjection(localeId)
  return groq`{
    ...${RICH_TEXT_PROJECTION},
    _type == "sectionQuote" => ${SECTION_QUOTE_PROJECTION},
    _type == "sectionIconWithTextList" => ${SECTION_ICON_WITH_TEXT_LIST_PROJECTION},
    _type == "sectionVideoPlayer" => ${SECTION_VIDEO_PLAYER},
    _type == "sectionSingleMediaWithDescription" => ${SECTION_SINGLE_MEDIA_WITH_DESCRIPTION},
    _type == 'sectionQuoteWithVideo' =>  ${SECTION_QUOTE_WITH_VIDEO_PROJECTION},
    _type == 'sectionStats' =>  ${SECTION_STATS_PROJECTION},
  }`
}
