import { Button, Container, Link, Media, Socials } from '@portfolio/components'
import { LogoWhite } from '@portfolio/icons'
import { SiteConfiguration } from '@portfolio/models'
import { styled } from '@portfolio/styles'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

const Wrap = styled('div', {
  width: '100%',
  height: '100%',
  display: 'grid',
  alignContent: 'space-between',
  background: '$black',
  color: '$white',
  gap: '$24',
})

const DrawerHeader = styled(Container, {
  height: '$headerHeight',
  projectFont: 'body03',
  top: 0,
  left: 0,
  alignItems: 'center',
  display: 'grid',
  justifyContent: 'space-between',
  gap: '$24',
  gridAutoFlow: 'column',
})
const LogoWrapper = styled(Link, {
  width: '100%',
  borderRadius: '$r1',
  '&:focus-visible': {
    outline: '$focusBlue solid 3px;',
    outlineOffset: '8px',
  },
})
const CloseButton = styled(Button, {
  projectFont: 'body03',
})
const ListWrap = styled('ul', {
  display: 'grid',
  justifyItems: 'center',
  gap: '$40',
  padding: 0,
})
const NavWrap = styled('nav', {
  padding: '6px 0',
  display: 'grid',
  height: '100%',
  overflowY: 'auto',
})
const NavigationItem = styled('li', {
  projectFont: 'heading01',
  padding: 0,
  display: 'grid',
  variants: {
    withIcon: {
      true: {
        marginLeft: '-$24',
      },
    },
  },
})

const NavigationItemIcon = styled('span', {
  width: '$24',
  height: '$24',
  display: 'grid',
  alignSelf: 'center',
  margin: '$0 $8 0 0',
})

const SocialsWrap = styled('div', {
  marginBottom: '$32',
  justifyContent: 'center',
  display: 'flex',
})

type ContentProps = {
  onCloseClick?: (event: React.MouseEvent | React.KeyboardEvent) => void
  header: SiteConfiguration['header']
  theme: 'transparent-dark' | 'transparent-light'
}

export const MobileDrawer: FC<ContentProps> = ({ header, onCloseClick }) => {
  const { t } = useTranslation('header')
  return (
    <Wrap>
      <DrawerHeader>
        <LogoWrapper appearance="grid" href="/">
          <LogoWhite />
        </LogoWrapper>
        <CloseButton appearance="text" onClick={onCloseClick}>
          {t('close')}
        </CloseButton>
      </DrawerHeader>
      <NavWrap>
        <ListWrap>
          {header?.navLinks &&
            header?.navLinks?.length > 0 &&
            header?.navLinks?.map((navItem, index) => (
              <NavigationItem
                key={`${navItem?.ctaLabel}-${index}`}
                withIcon={!!navItem.ctaIcon}
              >
                {navItem.ctaLabel && navItem?.ctaLink && (
                  <Link appearance="grid" {...navItem.ctaLink}>
                    {navItem.ctaIcon?.mediaPayload && (
                      <NavigationItemIcon>
                        <Media
                          {...navItem.ctaIcon}
                          priority={false}
                          sizes={'40px'}
                        />
                      </NavigationItemIcon>
                    )}
                    {navItem.ctaLabel}
                  </Link>
                )}
              </NavigationItem>
            ))}
        </ListWrap>
      </NavWrap>
      <SocialsWrap>
        <Socials items={header?.socials?.items} />
      </SocialsWrap>
    </Wrap>
  )
}
