import { styled } from '@portfolio/styles'
import React from 'react'

export const StyledClickable = styled('button', {
  background: 'none',
  variants: {
    appearance: {
      containedWhite: {
        projectFont: 'ui01',
        color: '$black',
        background: 'linear-gradient(0deg, $black_005, $black_005), $white',
        borderRadius: '$rMax',
        height: '$buttonHeight',
        display: 'inline-flex',
        padding: '2px 24px 0 24px',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        transition: '0.2s background',
        whiteSpace: 'nowrap',
        '@lg': {
          '&:hover': {
            background: '$white',
          },
        },
        '&:active': {
          background: '$white_08',
        },
        '&:focus-visible': {
          outline: '$focusBlue solid 3px;',
          background: '$white',
        },
      },
      containedBlack: {
        projectFont: 'ui01',
        color: '$white',
        background: '$black',
        borderRadius: '$rMax',
        height: '$buttonHeight',
        display: 'inline-flex',
        padding: '2px 24px 0 24px',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        transition: '0.2s background',
        whiteSpace: 'nowrap',
        '@lg': {
          '&:hover': {
            background: '$black_08',
          },
        },
        '&:active': {
          background: '$black',
        },
      },
      containedTransparent: {
        projectFont: 'ui01',
        color: '$white',
        background: '$white_02',
        borderRadius: '$rMax',
        height: '$buttonHeight',
        display: 'inline-flex',
        padding: '2px 24px 0 24px',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
        transition: '0.2s background',
        backdropFilter: 'blur(10px)',
        '@lg': {
          '&:hover': {
            background: '$white_03',
          },
        },
        '&:active': {
          background: '$white_04',
        },

        '&:focus-visible': {
          outline: '$focusBlue solid 3px;',
          background: '$black_005',
        },
      },
      outlined: {
        projectFont: 'ui01',
        color: '$black',
        borderRadius: '$rMax',
        border: '1px solid $black',
        height: '$buttonHeight',
        display: 'inline-flex',
        padding: '2px 24px 0 24px',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
        transition: '0.2s color, 0.2s background ',
        '@lg': {
          '&:hover': {
            color: '$white',
            background: '$black',
          },
        },
        '&:active': {
          color: '$white',
          background: '$black_08',
        },
        '&:focus-visible': {
          outline: '$focusBlue solid 3px;',
          color: '$white',
          background: '$black',
        },
      },
      outlinedWhite: {
        projectFont: 'ui01',
        color: '$white',
        borderRadius: '$rMax',
        border: '1px solid $white',
        height: '$buttonHeight',
        display: 'inline-flex',
        padding: '2px 24px 0 24px',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
        transition: '0.2s color, 0.2s background ',
        '@lg': {
          '&:hover': {
            color: '$black',
            background: '$white',
          },
        },
        '&:active': {
          color: '$black',
          background: '$white_08',
        },
        '&:focus-visible': {
          outline: '$focusBlue solid 3px;',
          color: '$black',
          background: '$white',
        },
      },
      text: {
        color: 'currentcolor',
        transition: '0.2s opacity',
        '@lg': {
          '&:hover': {
            opacity: 0.6,
          },
        },
        '&:active': {
          opacity: 0.6,
        },
        '&:focus-visible': {
          outline: '$focusBlue solid 3px;',
        },
      },
      textUnderline: {
        color: 'currentcolor',
        transition: '0.2s opacity',
        textDecoration: 'underline',
        '@lg': {
          '&:hover': {
            opacity: 0.6,
          },
        },
        '&:active': {
          opacity: 0.6,
        },
        '&:focus-visible': {
          outline: '$focusBlue solid 3px;',
        },
      },
      grid: {
        display: 'grid',
        gridAutoFlow: 'column',
        projectFont: 'heading01',
        color: 'currentColor',

        '@lg': {
          projectFont: 'body03',
        },
      },
      default: {},
    },
    size: {
      large: {},
      medium: {},
      small: {},
    },
  },
  '&:disabled': {
    pointerEvents: 'none',
  },
  padding: 0,
  cursor: 'pointer',
})

type Appearance =
  | 'containedWhite'
  | 'containedBlack'
  | 'containedTransparent'
  | 'outlined'
  | 'outlinedWhite'
  | 'text'
  | 'textUnderline'
  | 'grid'
  | 'default'

export type StyledClickableProps = {
  size?: 'large' | 'medium' | 'small'
  appearance?: Appearance
  tabIndex?: number
  disabled?: boolean
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onFocus?: () => void
  onClick?: (event: React.MouseEvent | React.KeyboardEvent) => void
}
