import {
  isSupportedRegion,
  DEFAULT_REGION_ID,
  DEFAULT_LOCALE_ID,
  RegionId,
  LocaleId,
  isSupportedRegionLocale,
  regions,
} from '@portfolio/configs'

export const getLocaleRegionIdFromPath = (
  localeRegionString?: string,
): [regionId: RegionId, localeId: LocaleId] => {
  if (!localeRegionString) {
    return [DEFAULT_REGION_ID, DEFAULT_LOCALE_ID]
  }

  const [givenLocaleId, givenRegionId] = localeRegionString.split('-')

  const regionId: RegionId = (() => {
    if (!givenRegionId || !isSupportedRegion(givenRegionId)) {
      return DEFAULT_REGION_ID
    }
    return givenRegionId
  })()

  // regionId is always "supported" since we resolve it first
  const localeId: LocaleId = (() => {
    if (!givenLocaleId) {
      return (
        regions.find(({ id }) => id === regionId)?.defaultLocale ||
        DEFAULT_LOCALE_ID
      )
    }

    // locale is set but not supported for given region
    if (givenLocaleId && !isSupportedRegionLocale(regionId, givenLocaleId)) {
      return (
        regions.find(({ id }) => id === regionId)?.defaultLocale ||
        DEFAULT_LOCALE_ID
      )
    }

    return givenLocaleId
  })()

  return [regionId, localeId]
}
