import React, { ComponentPropsWithRef } from 'react'

import { styled } from '@portfolio/styles'
import {
  ModalContentPosition,
  ModalContentSize,
  ModalContentVariant,
  ModalTransition,
} from './Modal'

const ContentStyled = styled('div', {
  position: 'relative',
  border: 'none',
  maxWidth: '100%',
  variants: {
    position: {
      center: {
        margin: 'auto',
      },
      bottom: {
        margin: 'auto auto 0',
      },
      right: {
        margin: 'auto 0 auto auto',
      },
      left: {
        margin: 'auto auto auto 0',
      },
      top: {
        margin: '0 auto auto auto',
      },
    },
    size: {
      intrinsic: {},
      stretch: {
        width: '100%',
        height: '100%',
      },
      'stretch-x': {
        width: '100%',
      },
      'stretch-y': {
        height: '100%',
        width: '100%',
        overflowX: 'hidden',
        overflowY: 'auto',

        '@sm': {
          width: 'auto',
        },
      },
    },
    variant: {
      default: {
        background: 'white',
      },
      'white-rounded': {
        borderRadius: '$r2',
        overflow: 'hidden',
        background: 'white',
      },
      rounded: {
        borderRadius: '$r2',
      },
    },
    transition: {
      'slide-from-right': {
        [`&.base`]: {
          transition: 'transform .3s cubic-bezier(0.5, 0, 0.1, 1)',
          transform: 'translateX(100%)',
        },
        [`&.after-open`]: {
          transition: 'transform .3s cubic-bezier(0.5, 0, 0.1, 1)',
          transform: 'none',
        },
        [`&.before-close`]: {
          transition: 'transform .3s cubic-bezier(0.5, 0, 0.1, 1)',
          transform: 'translateX(100%)',
        },
      },
      fade: {
        [`&.base`]: {
          opacity: 0,
        },
        [`&.after-open`]: {
          opacity: 1,
        },
        [`&.before-close`]: {
          opacity: 0,
        },
      },
    },
  },
})

type ContentProps = ComponentPropsWithRef<'div'> & {
  onRequestClose?: (event: React.MouseEvent | React.KeyboardEvent) => void
  position?: ModalContentPosition
  variant?: ModalContentVariant
  size?: ModalContentSize
  transition: ModalTransition
}

export const Content = React.forwardRef<HTMLDivElement, ContentProps>(
  (
    {
      children,
      variant,
      position,
      size,
      onRequestClose,
      transition,
      ...restProps
    },
    ref,
  ) => {
    const stylingProps = { variant, position, size, transition }
    return (
      <ContentStyled {...restProps} ref={ref} css={{}} {...stylingProps}>
        {children}
      </ContentStyled>
    )
  },
)

Content.displayName = 'Content'
