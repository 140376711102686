import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { groq } from 'next-sanity'
import { LocaleId } from '@portfolio/configs'
import { createLinkProjection } from '../projections/createLinkProjection'

export const createSectionTextBlockWithLinkProjection = (
  localeId: LocaleId,
) => {
  const LINK_PROJECTION = createLinkProjection(localeId)

  return groq`{
        _key,
        _type,
        ${coalesceLocaleField('title', localeId)},
        ${coalesceLocaleField('text', localeId)},
        ${coalesceLocaleField('ctaLabel', localeId)},
        ctaLink${LINK_PROJECTION},
      }
  `
}
